@import "../../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../../assets/styles/mixins";

.MuiButtonBase-root.MuiButton-root.GenericButton {
    padding: 5px 12px;
    justify-content: center;
    border-radius: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    gap: 10px;
    border: 2px solid $primaryDark;

    &.ToggleBtn {
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    }

    &.LightGreenStyle {
        background-color: $primaryLight;
        color: $primaryDark;
        border: none;
    }

    &.FilledStyle {
        background-color: $primaryDark;
        color: $white;
    }

    &.HollowStyle {
        background-color: $white;
        color: $primaryDark;

        .svg {
            fill: currentColor;
        }
    }
    &:disabled {
        background-color: $grey200;
    }
}
