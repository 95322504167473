@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.booking-card {
    animation: fadeIn 0.5s ease-out forwards;
    background-color: $white;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    padding: 1rem 1.5rem;
    padding-bottom: 1.7rem;
    border: 1px solid $grey100;
    border-radius: 8px;
    color: $primaryDark;
    letter-spacing: -0.02em;
    margin-top: 9rem;
    height: max-content;
    width: max-content;
    position: relative;

    .title-container {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 700;
    }

    .dates-container {
        margin-top: 13px;
        display: flex;
        font-size: 0.875rem;
        gap: 6px;

        .estimated-container {
            display: flex;
            flex-direction: column;
            line-height: 1.5rem;

            .date-data {
                color: $grey300;
            }
        }

        .arrow-icon {
            margin-top: 8px;
            min-width: 8px;
        }

        @include breakpoint-xl {
            gap: 1rem;
        }
    }

    .separator-line {
        border: 0;
        height: 1px;
        background-color: $grey100;
        margin-top: 1rem;
    }

    .days-between-container {
        font-size: 0.75rem;
        color: $grey300;
        float: right;
    }

    @include breakpoint-lg-max {
        display: none;
    }
}
