@import "../../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../../assets/styles/mixins";

.BankTransferView {
    width: 100%;
    height: 100%;
    padding: 32px 47px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    overflow-y: auto;
    @include breakpoint-xl() {
        gap: 1.5rem;
    }
    .Title {
        text-align: center;
        line-height: normal;
        @include breakpoint-xl() {
            line-height: 1.5;
        }
    }
    .SubTitle {
        text-align: center;
    }
    .FormSection {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        @include breakpoint-xl() {
            gap: 1.5rem;
        }
        .ButtonsContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding-top: 0.25rem;
            .CancelButton {
                padding: 0.3rem 1rem;
            }
            .SubmitButton {
                padding: 0.3rem 1rem;
            }
        }
    }
}
