.navigationBarTooltip {
    background-color: #D9F7F1;
    width: 233px;
    height: 70px;
    padding: 10px;
    box-shadow: 0px 2px 20px 0px #00000024;
    border-radius: 8px 8px 8px 0px;
    display: flex;
    font-size: 14px;
    line-height: 17px;

    .announcementIcon {
        height: 24px;
        width: 24px;
    }

    .tooltipText {
        margin-top: 10px;
        width: 160px;
        flex-grow: 1;
        margin-left: 3px;
    }

    .closeIcon {
        cursor: pointer;
    }
}