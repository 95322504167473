@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.ShipmentCardLoader {
    $left-container-width: 25%;
    width: 100%;
    border: 1px solid $grey100;
    display: flex;
    height: 30%;
    border-radius: 0.5rem;

    .startContainer {
        // border-left: styled by parent component.
        padding: 1.5rem 1rem;
        border-right: 1px solid $grey100;
        width: $left-container-width;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        color: $primaryDark;
        .Loader {
            height: 0.8rem;
        }

        .Loader1 {
            width: 50%;
            height: 1.8rem;
        }
        .Loader2 {
            width: 80%;
        }
        .Loader3 {
            width: 60%;
        }
        .Loader4 {
            width: 90%;
        }
    }
    .endContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - $left-container-width);
        padding: 1.5rem 2rem;
        gap: 0.5rem;
        .Loader {
            height: 0.5rem;
        }
        .TopLoaderSection {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .TopLoadersContainer {
                width: 50%;
                padding-left: 30%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                justify-self: flex-end;
                gap: 0.5rem;
                .Loader1 {
                    width: 100%;
                }
                .Loader2 {
                    width: 60%;
                }
            }
        }
        .Loader3 {
            width: 100%;
            height: 0.75rem;
        }
        .BottomLoadersContainer {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            .LeftLoadersContainer {
                width: 30%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                .Loader4 {
                    width: 100%;
                }
                .Loader5 {
                    width: 50%;
                }
            }
            .middleLoadersContainer {
                width: 60%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 0.5rem;
                .Loader6 {
                    width: 100%;
                }
                .Loader7 {
                    width: 60%;
                }
            }
            .rightLoadersSection {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .rightLoadersContainer {
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;
                    .Loader8 {
                        width: 100%;
                    }
                    .Loader9 {
                        width: 60%;
                    }
                }
            }
        }
    }
}
