@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.UpdateHistoryCard {
    background-color: $white;
    z-index: 1;
    padding: 1rem 1.25rem;
    border: 1px solid $grey100;
    border-radius: 8px;
    color: $primaryDark;
    letter-spacing: -0.02em;
    position: absolute;
    top: -80px;
    height: max-content;
    min-width: max-content;

    .TitleContainer {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 700;
    }

    .DatesContainer {
        margin-top: 13px;
        display: flex;
        font-size: 0.875rem;
        gap: 6px;

        .EstimatedContainer {
            display: flex;
            flex-direction: column;
            line-height: 1.5rem;

            .EstimatedDateData {
                color: $grey200;
                text-decoration: line-through;
            }
            .ActualDateData {
                color: $grey300;
            }
        }

        .ArrowIcon {
            margin-top: 8px;
            min-width: 8px;
        }

        @include breakpoint-xl {
            gap: 1rem;
        }
    }

    @include breakpoint-md-max {
        display: none;
    }
    @include breakpoint-lg-max {
        left: 100px !important
    }
}