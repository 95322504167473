@import "../../assets/styles/mixins";

.pill-container {
    display: flex;
    align-items: start;
    color: white;
    font-size: 14px;
     height: fit-content;
    gap: 16px;
.pill-icon{
    height: 24px;
    width: 24px;
}
    .pill-text {
        font-family: "Lato-Bold";
        white-space: nowrap;

        @include breakpoint-xl {
            max-width: 420px;
        }

        @include breakpoint-between-desktops(1200px, 1899px) {
            max-width: 200px;
        }
    }
    .pill-text-content{
    display: flex;
    flex-direction: column;
    }
    .pill-content-container {
        display: flex;
        gap: 0.4rem;
    }

    @include breakpoint-lg-max {
        display: none;
    }
}
