@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.Root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1rem;
    padding-top: 1rem;

    @include breakpoint-lg {
        padding-top: 0rem;
    }

    .ShipmentPageHead {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .HeaderTitleContainer {
            display: flex;
            flex-direction: column;

            .HeaderTitle {
                font-family: "Lato-Bold";
                font-weight: 700;
            }
        }
    }

    .HeaderButtonContainer {
    }

    .ShipmentPageBody {
        @include breakpoint-lg {
            height: calc(100% - 3.3rem);
            display: flex;
            gap: 1rem;
        }
        
        &.noActiveShipment {
            @include breakpoint-lg {
                grid-template-columns: 1fr;
            }
        }
    }
    .ShipmentDetailsBlock {
        height: 100%;
        overflow: hidden;
        min-width: 60%;
    }
    .Block {
        display: none;

        @include breakpoint-lg {
            display: unset;
        }
    }

    .HeaderTitleSpan {
        font-family: "Lato-Thin";
    }
}
