@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.ShipmentsDetailsBlockHeaderLoader {
    gap: 1rem;
    display: flex;
    padding: 1rem;
    flex-direction: column;
}

.FirstRowHeaderContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.LeftSideContainer{
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
}

.FirstRow{
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.FirstRowLeftItem{
    width: 150px;
    height: 1rem;
}

.FirstRowRightItem{
    width: 75px;
    height: 2rem;
}

.SecondRowItem{
    width: 350px;
    height: 1rem;
}

.contactUsButton{
    border-radius: 39px !important;
    border: 1px solid $primaryDark !important;
    color: $primaryDark !important;
}

.PillContainer{
   height: 121px;
}