@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.PillContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $primaryDark;
    border-radius: 87px 0 0 87px;
    padding: 21px 4.2rem;
    width: 110%;
    position: relative;
    gap: 3rem;
    
    .PillContainerBackgroundSvg {
        $containerWidth: 10%;
        position: absolute;
        right: $containerWidth;
        justify-self: center;
        height: 100%;
        z-index: $zIndexBackground;
        @include breakpoint-lg() {
            right: 8%;
        }
        @include breakpoint-xl() {
            right: 7%;
        }
        &.PushToRight {
            @include breakpoint-lg() {
                @media (max-width: calc($desktopMinWidth + 400px)) {
                    right: calc(8% - #{$containerWidth});
                }
            }
            @include breakpoint-xl() {
                @media (max-width: calc($desktopLargeMinWidth + 100px)) {
                    right: calc(8% - #{$containerWidth});
                }
            }
        }
    }
    .PillContainerLeftItem {
        color: white;
        justify-content: flex-start;
        align-items: center;
    }
    .PillContainerRightItem {
        color: white;
        justify-content: flex-end;
        align-items: center;
    }
}
