@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";
@import "../../assets/styles/functions";

.FilterNavbar {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 0.1rem;
    width: 100%;
    gap: 0.625rem;
    min-height: max-content;
    .Filters {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: row wrap;
        height: 100%;
        gap: rem(6px);

        @include breakpoint-desktop-med() {
            gap: 0.625rem;
        }
    }
    .DefaultViewBtn {
        background: none;
        border: none;
        appearance: none;
        text-decoration: underline;
        color: $primaryDark;
        text-underline-offset: 2px;
        cursor: pointer;
    }
}
