@import "../../../../../assets/styles/v2-styles/UiScheme.module.scss";


.ShipmentCardDashboardTransit {
    .start-container {
        border-left: 10px solid $secondaryGreen;
        border-radius: 8px 0px 0px 8px;

        .start-container__title-container {
            color: white;
            background-color: #1D4650;
            border-radius: 50px;
            width: 90px;
            display: inline-flex;
            height: 24px;
            padding: 6px 12px 6px 10px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex-shrink: 0;
            .title-dot{
                background-color: $secondaryGreen;
            }
        }
    }
  
}
