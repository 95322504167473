@import "../../assets/styles/v2-styles/UiScheme.module.scss";

.UnicargoCheckbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 100px;
    &.Filled {
    }
    &.Empty {
    }
    &.Disabled {
        cursor: not-allowed;
    }
}
