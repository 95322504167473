@import "../../assets/styles/v2-styles/UiScheme.module.scss";

.step-date {
    color: $grey300;
    position: relative;
    .dates-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        .deleted-date {
            text-decoration: line-through;
            color: $grey200;
        }

        .display-tooltip {
            text-decoration: underline line-through;
            cursor: pointer;
        }
    }
}

.disabled-date {
    color: $grey200;
}
