.DecorativeLine {
    $border-radius: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    border-radius: $border-radius $border-radius $border-radius $border-radius;
    border: 0;
    .SideLine {
        height: 100%;
        &.LeftLine {
            border-radius: $border-radius 0 0 $border-radius;
        }
        &.RightLine {
            border-radius: 0 $border-radius $border-radius 0;
        }
    }
    .Circle {
        transform: translate(-50%);
        position: absolute;
        border-radius: 50%;
        left: 50%;
    }
}
