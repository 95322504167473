@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.DotsLoader {
    height: 1rem;
    width: 100%;
    border-radius: 100px;
    background-color: $greyBackground;
    animation: pulse 1.5s infinite ease-in-out;
    &.Bar {
    }
    &.Circle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
    }
    &.Loading {
        animation-duration: 0s;
    }
}

// create a pulsing animation from white to grey
@keyframes pulse {
    0% {
        background-color: $greyBackground;
    }
    25% {
        background-color: $grey100;
    }
    50% {
        background-color: $greyBackground;
    }
    100% {
        background-color: $greyBackground;
    }
}
