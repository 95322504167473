.BaseButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;   
    .children-container {
        display: flex;
        width: max-content;
        &.normal {
            font-size: var(--font-size-title);
        }

        &.small {
            font-size: var(--font-size-text);
        }
    }

    .arrow-right-icon {
        &.normal {
            width: 6px;
            height: 10px;
        }

        &.small {
            width: 4px;
            height: 8px;
        }
    }
}
.background-gif {
    background-image: url("../../../assets/gifs/fireworks.gif");
    background-size: cover;
    background-position: center center;
}
.Mui-disabled {
    background-image: none;
}
