.UnicargoTooltipOriginalComponent {
}
.UnicargoTooltipPopper {
    &.MuiTooltip-popper {
        .MuiTooltip-tooltip {
            background-color: white;
            color: black;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.24);
            line-height: 17px;
            font-weight: 400;
            padding: 10px;
            font-family: Lato-Regular;
            margin-bottom: 5px !important;
            right: 8px;
            text-transform: capitalize !important;
        }
        .MuiTooltip-arrow {
            color: white;
        }
    }
}
