@import "../../assets/styles/mixins";
$tab-transition: 0.25s ease-in-out;

.NavigationBar {
    z-index: $zIndexBase;
    width: 100%;

    * {
        z-index: $zIndexBase;
    }
    ul.navigationTabsContainer {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 100%;

        // Apply only on mobile
        *:not(:last-child) {
            border-bottom: 1px solid var(--gray-light);
        }

        .navigationTab {
            color: $grey300;
            text-decoration: none;

            svg path {
                // stroke: #bdbfc2;
                stroke: $grey100;
                fill: $grey100;
            }

            svg {
                width: 32px;
                height: 32px;
            }

            // Apply only on mobile
            .iconContainer {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                padding: 0.75rem 0;
            }

            // While tab is active
            &.activeTab {
                span {
                    opacity: 1;
                }

                svg path {
                    stroke: var(--color-destination);
                    fill: var(--color-destination);
                }
                &.specialIcon {
                    svg path {
                        stroke: var(--color-destination);
                        fill: var(--color-destination);
                    }
                    fill: var(--color-destination);
                }
            }
        }

        /* Tablet & Desktop*/
        @include breakpoint-lg() {
            *:not(:last-child) {
                border-bottom: 0;
            }
            li.navigationTab {
                transition: $tab-transition;
                display: flex;
                flex-direction: column;

                svg path {
                    transition: $tab-transition;
                }
                .iconContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    font-size: var(--font-size-text);
                    color: var(--gray-dark);
                    padding: 1rem 0;
                    position: relative;

                    .comingSoonText {
                        position: absolute;
                        background-color: #d9f7f1;
                        font-size: 10px;
                        width: 34px;
                        height: 14px;
                        border-radius: 43px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        bottom: 32px;
                    }
                }

                // While hovering over a non-active tab
                :hover:not(.activeTab) {
                    cursor: pointer;
                    // color: var(--gray-medium);

                    svg {
                        path {
                            transition: $tab-transition;
                            stroke: $grey200;
                            fill: $grey200;
                        }
                    }
                    &.specialIcon {
                        svg path {
                            stroke: var(--gray-medium);
                            fill: var(--gray-medium);
                        }
                    }
                }

                // When leaving :hover
                &:not(:hover) {
                    svg {
                        path {
                            transition: $tab-transition;
                            stroke: $grey100;
                            fill: $grey100;
                        }
                    }
                }

                // While tab is active
                .activeTab {
                    background-color: var(--color-dashboard-bg);
                    color: $grey300;

                    svg {
                        // fill: var(--color-destination);
                        path {
                            stroke: var(--color-destination);
                            fill: var(--color-destination);
                        }
                    }
                    &.specialIcon {
                        svg path {
                            stroke: var(--color-destination);
                            fill: var(--color-destination);
                        }
                    }

                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        top: -8px;
                        right: 0;
                        height: 8px;
                        width: 8px;
                        box-shadow: 4px 4px 0 4px var(--color-dashboard-bg);
                        border-bottom-right-radius: 8px;
                        z-index: -1;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -8px;
                        right: 0;
                        height: 8px;
                        width: 8px;
                        box-shadow: 4px -4px 0 0 var(--color-dashboard-bg);
                        border-top-right-radius: 8px;
                    }
                }
            }
        }
    }
}
