@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.icon-container {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-wrapper.disable {
    color: $grey200;
}

.disable-line {
    color: $grey200;
}

.step-description-and-date-container.indentation {
    padding-left: calc(24px + 0.4rem);
    display: flex;
}

.step-wrapper {
    display: flex;
    flex-direction: column;

    .step-location-container {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        font-size: 13px;
        font-family: "Lato-Bold";
        flex-wrap: wrap;
        @include breakpoint-md-max {
            font-size: 12px;
        }
    }

    .step-description-item.highlight {
        border-radius: 4px;
        margin-left: calc(24px + 0.2rem);
        background-color: $greyBackground;
        padding: 5px;
        flex-wrap: wrap;

        .icon-container {
            display: none;
        }
    }

    .step-description-and-date-container {
        display: flex;
        gap: 0.4rem;
        flex-wrap: wrap;

        .step-description-and-icon-container {
            display: flex;
            gap: 0.4rem;
            align-items: center;

            .step-description {
                display: flex;
                flex-direction: column;
                gap: 0.4rem;
                margin-top: 0.4rem;
                .step-description-item {
                    display: flex;
                    gap: 0.4rem;
                    align-items: center;
                    flex-wrap: wrap;
                }
            }
        }

        // This is a hacky way so that when the dates span over one line, there will still be indentation
        .description {
            margin-right: -32px;
            font-size: 12px;
            flex-wrap: wrap;
            @include breakpoint-md-max {
                font-size: 10px;
            }
        }
        .dates {
            margin-left: calc(24px + 0.4rem);
            font-size: 12px;
            flex-wrap: wrap;
            @include breakpoint-md-max {
                font-size: 10px;
            }
        }
    }
}
