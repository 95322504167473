@import "../../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../../assets/styles/mixins";

.SidebarDesktop {
    max-width: 70px;
    background-color: var(--components-bg-color);
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: $zIndexHigherOrderElements;
    height: 100vh;
    * {
        z-index: $zIndexBase;
    }

    /* Sidebar - top section */
    .sidebarTopSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        width: 100%;
    }

    /* Sidebar - bottom section */
    .sidebarBottomContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        .icon {
            padding: 0;
            margin: 0;
            border: none;
            background-color: transparent;
            width: 100%;
            &:hover {
                text-decoration: underline;
                svg path {
                    fill: #30606b;
                }
                cursor: pointer;
                &:active {
                    text-decoration: underline;
                }
            }
        }

        .emailUsIconContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.75rem;
            margin-right: initial;
            font-size: var(--font-size-text);
            line-height: 11px;
            svg {
                width: 24px;
                height: 24px;
            }
        }

        .divider {
            width: 80%;
            margin-top: 1.5rem;
            border-top: 1px solid var(--color-dividers);
        }

        .customerIconContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;

            .customerIcon {
                width: 37px;
                height: 37px;
                border-radius: 50%;
                background-color: #1d4650;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: var(--font-size-title);
                color: var(--gray-white);
                cursor: pointer;
                &:hover {
                    background-color: #30606b;
                }
            }
        }
    }
}

.userOptions {
    padding: 0.25rem 1rem;
    .userOption {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        text-align: start;
        width: 100%;
        height: 100%;
        font-family: var(--font-regular);
        font-size: 14px;
        .userOptionButton {
            cursor: pointer;
            padding: 0;
            border: none;
            background: none;
            text-align: start;
            width: 100%;
            padding: 8px;
            color: $primaryDark;
            &:hover {
                text-decoration: underline;
                text-underline-offset: 0.25rem;
            }
        }
    }
    .divider {
        margin: 0.5rem;
        height: 1px;
        background-color: var(--color-dividers);
    }
}
