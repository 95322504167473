@import "../../assets/styles/v2-styles/UiScheme.module.scss";

.UnicargoTextInfoContainer {
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: $greyBackground;
    border-radius: 1rem;
    .TextInfoContentLabel {
        color: $grey300;
        font-size: 14px;
    }
    .TextInfoContentValue {
        color: $primaryDark;
    }
}
