@import "../../assets/styles/v2-styles/UiScheme.module.scss";

.UnicargoKeyValueTextInfoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: $greyBackground;
    border-radius: 1rem;
    .KeyValueContainer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
        color: $primaryDark;
            font-size: 12px;
        .Key {
            white-space: nowrap;
        }
        .Value {
        }
    }
}
