@import "./assets/styles/v2-styles/UiScheme.module.scss";
@import "./assets/styles/mixins";
@import "./assets/styles/functions";

.App {
    display: flex;
    flex-direction: column;
    background-color: $greyBackground;
    height: 100vh;
    z-index: $zIndexBackgroundImage;
    overflow: hidden;
    & > * {
        z-index: $zIndexBase;
    }

    @include breakpoint-lg() {
        flex-direction: row;
    }

    .outletContainer {
        padding: rem(24px) rem(16px);
        overflow-x: hidden;
        height: 100%;

        @include breakpoint-lg() {
            width: 100%;
        }
        @include breakpoint-desktop-med() {
            padding: 1.5rem;
        }
        @include breakpoint-xl() {
            padding: 1.5rem 3rem;
        }
    }
}
