@import "../../assets/styles/mixins";

@mixin center() {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SelectCustomerPage {
    height: 100vh;
    width: 100vw;
    position: relative;
    padding: 1.125rem;
    overflow: hidden;

    &.rfq-view {
        height: 100%;
        width: 100%;
    }

    .LoaderContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100%;
        transform: translate(-2.250rem, -2.250rem);
    }

    .select-customer-background {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 200px;
        height: 230px;
        pointer-events: none;
        user-select: none;
    }
    .select-customer-header {
        width: 100%;
        height: auto;
        display: block;
        text-align: center;
        margin-bottom: 3.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        & .back-btn {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
        }
        & .unicargo-logo {
            margin-top: 2.06rem;
        }
    }
    .select-customer-title {
        color: $primaryDark;
        text-align: center;
        pointer-events: none;
        user-select: none;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: 0.25rem;
        span {
            display: block;
        }
    }
    .select-customer-sub-title {
        color: $primaryDark;
        text-align: center;
        pointer-events: none;
        user-select: none;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25rem;
        margin-bottom: 2rem;
    }
    .select-customer-box-container {
        @include center();
        margin-bottom: 2.5rem;
        .select-customer-box {
            width: 100%;
            height: 3rem;

            @include breakpoint-sm() {
                width: 28.125rem;
            }
        }
    }
    .select-customer-button-container {
        @include center();
        margin-bottom: 1rem;
        .select-customer-login-button {
            text-transform: none;
            border-left: none;
            color: $primaryDark;
            background: $primaryLight;
            font-weight: 500;
            border-radius: 30px;
            font-size: 0.8125rem;
            margin: 0 auto;
            font-family: 'Lato-Regular';
            padding: 5px 16px;
            text-align: center;
            &:disabled {
                cursor: not-allowed;
                pointer-events: unset;
            }
        }
    }
    .help-button {
        display: flex;
        justify-content: center;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: -0.02em;
        border: none;
        color: #3b4858;
        margin: 0 auto;
        text-align: center;
        text-decoration: none;
        font-family: 'Lato-Regular' !important;
        cursor: pointer;
        &:hover {
            background: none;
        }
        &:focus {
            outline: none;
            background: none;
            border: none;
            box-shadow: none;
        }
    }
    .help-button {
        padding: unset;
        font-size: 0.875rem;
        display: flex;

        svg {
            height: 1rem;
            width: 1rem;
            margin-left: 7px;
            margin-right: 4px;
        }
        span {
            font-weight: 600;
        }
    }
}

@include breakpoint-md() {
    .SelectCustomerPage {
        padding: 2.125rem;
        .select-customer-background {
            width: auto;
            height: auto;
        }
        .select-customer-header {
            margin-bottom: 2.25rem;
        }
        .select-customer-title {
            font-size: 1.5rem;
            line-height: 1.8rem;
            margin-bottom: 0.5rem;

            span {
                margin-bottom: 0.5rem;

                &:nth-child(2) {
                    margin-bottom: 1.5625rem;
                }
            }
        }
        .select-customer-sub-title {
            font-size: 1rem;
            line-height: 1rem;
            margin-bottom: 3rem;
        }
    }
}
