@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.Summary {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    margin: 0;
    * {
        z-index: $zIndexBase;
        margin: 0;
    }
    .SummaryDescription {
        display: flex;
        flex-direction: row;
        gap: 9px;
        .SummaryDescriptionTitle {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: $fontSizes-h2;
            font-family: "Lato-Bold";
        }
        .SummaryDescriptionText {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            font-size: $fontSizes-p;
        }
        @include breakpoint-xl() {
            flex-direction: column;
            gap: 4px;
            color: white;
        }
    }
    .PillContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $primaryDark;
        height: 110px;
        border-radius: 87px;
        padding: 21px 4.2rem;
        width: 110%;
        position: relative;
        gap: 3rem;
        .ErrorText {
            color: white;
        }
        .PillContainerBackgroundSvg {
            $containerWidth: 10%;
            position: absolute;
            right: $containerWidth;
            justify-self: center;
            height: 100%;
            z-index: $zIndexBackground;
            @include breakpoint-lg() {
                right: 8%;
            }
            @include breakpoint-xl() {
                right: 7%;
            }
            &.PushToRight {
                @include breakpoint-lg() {
                    @media (max-width: calc($desktopMinWidth + 400px)) {
                        right: calc(8% - #{$containerWidth});
                    }
                }
                @include breakpoint-xl() {
                    @media (max-width: calc($desktopLargeMinWidth + 100px)) {
                        right: calc(8% - #{$containerWidth});
                    }
                }
            }
        }
        .PillContainerItem {
            display: grid;
            color: white;
            justify-content: flex-start;
            align-items: center;
            grid-template-areas:
                "CurrencySymbol CurrencySymbol CurrencySymbol"
                "TotalCurrencyAmount OverdueCurrencyAmount QuickSelectionButton";
            row-gap: 0.5rem;
            column-gap: 1rem;
            min-width: max-content;
            .CurrencySymbol {
                grid-area: CurrencySymbol;
                display: flex;
                flex-direction: column;
                color: white;
                font-size: $fontSizes-p;
            }

            .TotalCurrencyAmount {
                grid-area: TotalCurrencyAmount;
                color: white;
                font-size: $fontSizes-mainPrices;
            }
            .OverdueCurrencyAmount {
                grid-area: OverdueCurrencyAmount;
                color: $secondaryRed;
                font-size: $fontSizes-secondaryPrices;
            }

            .QuickSelectionButton {
                grid-area: QuickSelectionButton;
                margin-left: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }
        .EmptyState {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            .EmptyStateText {
                font-family: "Lato-Bold";
                font-size: $fontSizes-h2;
                color: white;
            }
        }
        .SummarySkeletonLoaderContainer {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            .SummarySkeletonLoaderItem {}
        }
        &.MultipleItems {
            .PillContainerItem {
                @include breakpoint-lg() {
                    grid-template-areas:
                        "CurrencySymbol CurrencySymbol CurrencySymbol"
                        "TotalCurrencyAmount TotalCurrencyAmount QuickSelectionButton"
                        "OverdueCurrencyAmount OverdueCurrencyAmount QuickSelectionButton";
                    row-gap: 0.25rem;
                    column-gap: 0.5rem;
                }
                @include breakpoint-xl() {
                    grid-template-areas:
                        "CurrencySymbol TotalCurrencyAmount QuickSelectionButton"
                        "CurrencySymbol OverdueCurrencyAmount QuickSelectionButton";
                    row-gap: 0.25rem;
                    column-gap: 1rem;
                    .CurrencySymbol {
                        height: 120%;
                    }
                }
            }
        }
        @include breakpoint-xl() {
            gap: 40px;
            padding: 21px 3rem;
        }
    }
    @include breakpoint-xl() {
        width: 100%;
    }
}
