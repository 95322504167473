.NotificationCardNegative {
    &.desktop {
        .notification-card_details-container {
            .bottom-container {
                .details-inner-container {
                    .previous-message {
                        text-decoration: line-through;
                        opacity: 0.6;
                    }
                }
            }
        }
    }
    &.mobile {
        .notification-card-header-container {
            background-color: var(--color-error);
        }

        .notification-card-content-container {
            .content_details-container {
                .details-container_previous-message {
                    text-decoration: line-through;
                    color: var(--color-error);
                    opacity: 0.6;
                }
            }
        }
    }
}
