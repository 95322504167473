@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1rem;

    flex: 1 0 auto;
    .PageHead {
        display: none;
        height: 0;
        display: flex;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .HeaderTitle {
            font-family: "Lato-Bold";
            font-weight: 700;
        }
    }

    .PageBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1.5rem;
        @include breakpoint-xl() {
            gap: 2rem;
        }
    }

    .HeaderTitleSpan {
        font-family: "Lato-Thin";
    }
}
