@import "../../assets/styles/v2-styles/UiScheme.module.scss";

.UnicargoSearchBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    height: 2rem;
    width: 276px;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    border: 1px solid transparent;

    .ClearBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: $grey100;
        }
    }

    &:hover {
        border-color: $grey100;
    }

    &:focus-within {
        border-color: $primaryDark;
    }
    .SearchBarTextField {
        flex-grow: 1;
        input {
            color: $primaryDark;
            padding: 0;
            font-size: 14px;
        }
        .MuiTextField-root {
        }
        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }
}
