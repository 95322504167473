@import "../../../assets/styles/mixins";
@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

$padding: var(--default-gap);
.NotificationsBlock {
    display: flex;
    flex-direction: column;
    padding: $padding 0;
    gap: 1rem;
    height: 100%;

    .titleContainer {
        display: flex;
        justify-content: space-between;
        padding: 0 2.625rem 0 $padding;
        .titleWrapper {
            display: flex;
            align-items: baseline;
            gap: 0.5rem;
            h2.title {
                color: var(--gray-dark);
            }

            .subTitle {
                font-size: var(--font-size-title);
                color: var(--gray-medium);
            }
        }
        .clearNotificationsButton {
            display: flex;
            align-items: center;
            border-radius: 8px;
            padding: 0.125rem 0.625rem;
            border: 1px solid $primaryDark;
            gap: 0.5rem;
            border-radius: 39px;
            span {
                padding: 5px;
                font-size: 14px;
                color: $primaryDark;
            }
            &:hover {
                span{
                    color: white;
                }
                svg path {
                    fill: white;
                }
                cursor: pointer;
                background-color: $primaryDark;
            }
        }
    }

    .notificationCardsContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 0 0 1rem;
        overflow-y: scroll;

        &.mobile {
            max-height: 450px;
        }
    }
}

.LoaderContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1rem;
    padding: $padding;
    overflow: hidden;
    .TopContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 0.5rem;
        gap: 0.5rem;
        .TopLeftLoader {
            width: 20%;
            height: 1.375rem;
        }
        .TopRightLoader {
            width: 15%;
            height: 1.75rem;
        }
    }
    .NotificationCardLoadersContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .NotificationCardLoader {
            width: 100%;
            height: 3.5rem;
            border-radius: 0.5rem;
            border: 1px solid $grey100;
            padding: 11px;
            display: flex;
            justify-content: space-between;
            .LeftNotificationCardContainer {
                display: grid;
                grid-template-areas:
                    "Square LineTop"
                    "Square LineBottom";
                grid-template-columns: auto 1fr;
                grid-template-rows: 1fr 2fr;
                gap: 0.5rem;
                width: 20%;
                .Square {
                    grid-area: Square;
                    $squareSideSize: 2rem;
                    width: $squareSideSize;
                    height: $squareSideSize;
                    border-radius: 0.25rem;
                }
                .LineTop {
                    grid-area: LineTop;
                    width: 100%;
                    height: 0.9rem;
                }
                .LineBottom {
                    grid-area: LineBottom;
                    width: 80%;
                    height: 0.75rem;
                }
            }
            .RightNotificationCardContainer {
                display: grid;
                grid-template-areas:
                    "LineTopContainer Circle"
                    "LineBottom Circle";
                grid-template-columns: 1fr auto;
                grid-template-rows: 1fr 2fr;
                gap: 0.5rem;
                width: 30%;
                .Circle {
                    grid-area: Circle;
                    $circleSideSize: 2rem;
                    width: $circleSideSize;
                    height: $circleSideSize;
                }
                .LineTopContainer {
                    grid-area: LineTopContainer;
                    width: 100%;
                    height: 0.75rem;
                    display: flex;
                    flex-flow: row-reverse;
                    .LineTop {
                        height: 100%;
                        width: 50%;
                    }
                }
                .LineBottom {
                    grid-area: LineBottom;
                    width: 100%;
                    height: 0.9rem;
                }
            }
        }
    }
}

.ErrorContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .ErrorTitle {
    }
    .ErrorSubTitle {
    }
}
