.ShipmentCardDashboardBase {
    $left-container-width: 242px;
    width: 100%;
    border: 1px solid var(--gray-light);
    display: flex;
    height: initial; // disable Block's height: 100%

    &:hover {
        cursor: pointer;
        background-color: #f3f3f3;
    }
    .start-container {
        // border-left: styled by parent component.
        padding: 1.5rem 1rem 1.5rem 1rem;
        border-right: 1px solid var(--gray-light);
        width: $left-container-width;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        color: #1d4650;
        overflow: hidden;

        .start-container__title-container {
            font-size: var(--font-size-text);
            // color: styled by parent component.
            .title-dot {
                width: 6px;
                height: 6px;
                border-radius: 50px;
            }
        }
        .start-container__shipment-name-container {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .shipment-name-container__icon {
                svg {
                    display: block;
                    width: 20px;
                    height: 20px;
                }
            }

            .shipment-name-container__shipment-name {
                font-size: var(--font-size-title);
            }

            .content_divider {
                border-left: 1px solid var(--gray-light);
                height: 100%;
            }

            .shipment-name-container__shipment-load {
                font-size: var(--font-size-title);
            }
        }
        .start-container__codes-container {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .codes-container__content {
                align-items: center;
                display: flex;
                gap: 0.5rem;
                font-size: var(--font-size-text);
                color: var(--gray-dark);
                .content__shipment-number {
                    display: flex;
                    gap: 4px;
                }
                .content__shipment-load {
                    display: flex;
                    gap: 4px;
                }
                .content_divider {
                    border-left: 1px solid var(--gray-light);
                }
                .content__purchase-order {
                }
            }
        }
        .start-container__volumes-container {
            display: flex;
            align-items: center;
            align-items: flex-start;
            gap: 0.5rem;
            .volumes-container__icon {
                svg {
                    display: block;
                    width: 20px;
                    height: 20px;
                }
            }
            .volumes-container__content {
                font-size: var(--font-size-text);
                color: var(--gray-dark);
                display: flex;
                flex-wrap: wrap;
                gap: 4px;

                .content__packages {
                    display: flex;
                    gap: 4px;
                }
                .content__gross-weight {
                    gap: 4px;
                    display: flex;
                }
                .content__cbm-volume {
                    gap: 4px;
                    display: flex;
                }
                .content_divider {
                    border-left: 1px solid var(--gray-light);
                }
            }
        }
    }
    .end-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 1rem .25rem;
        padding-bottom: 4.5rem;
        height: 168px;
        flex-grow: 1;
    }
}
