@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.stepper-container {
    background: $greyBackground;
    width: 18.5px;
    border-radius: 10px;
    margin-right: 20px;
    opacity: 0.5;
    z-index: 1;
    transition: all 0.1s ease-in-out;
    @include breakpoint-md-max {
        width: 20.5px;
        transform: translateY(54px);
    }
}
