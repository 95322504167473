@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.UnicargoPaginationBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    * {
        width: max-content;
    }

    @include breakpoint-desktop-med() {
        gap: 1rem;
    }
    .PaginationBtnsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        user-select: none;
        .PaginationBtnIcon {
            cursor: pointer;
            &.PreviousPageBtn {
                rotate: 180deg;
            }
            &.NextPageBtn {
            }
            &.DisabledBtn {
                cursor: not-allowed;
                * {
                    stroke: $grey200;
                }
            }
        }
    }
}
