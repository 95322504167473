@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.SpinnerLoader {
    $border-width: 6px;
    display: inline-block;
    &:after {
        content: "";
        display: block;
        height: calc(100% - 2 * $border-width);
        width: calc(100% - 2 * $border-width);
        border-radius: 50%;
        border: $border-width solid $grey100;
        border-color: $grey100 transparent $grey100 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
