@import "../../assets/styles/v2-styles/UiScheme.module.scss";

.UnicargoTextInput.MuiFormControl-root.TextInputField {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    height: 3.5rem;
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    background-color: white;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 0.5rem 1rem;
    &:hover {
        border-color: $grey100;
    }
    &:focus-within {
        border-color: $primaryDark;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
        height: 100%;
        input {
            width: 100%;
            height: 100%;
            color: $primaryDark;
            padding: 0;
            font-size: 14px;
            &::placeholder {
                font-size: 14px;
            }
        }
        .MuiOutlinedInput-notchedOutline {
            width: 100%;
            border: none;
        }
    }
}
