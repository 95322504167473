.empty-state-container{

    display: flex;
    padding: 0.5rem 1rem 0.5rem;
    align-items: center;
    gap: 0.5rem;

    .title {
        color: var(--gray-dark);
    }
    .sub-title {
        font-size: var(--font-size-title);
        color: var(--gray-medium);
    }
    .empty-state-svg{
        margin-top: 50px;
        width: 60%;
    }
     
}