@import "../../assets/styles/mixins";
@mixin center(){
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.NoCustomersPage{
    height: 100vh;
    width: 100vw;
    position: relative;
    padding: 1.125rem;
    overflow: hidden;
    .no-customers-header{
        width: 100%;
        height: auto;
        display: block;
        text-align: center;
        margin-bottom: 3.25rem;
    }
    .no-customers-title {
        color: #3B4858;
        text-align: center;
        pointer-events: none;
        user-select: none;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: 2rem;
        span {
            display: block;
        }
    }
    .no-customers-footer{
        @include center();
        pointer-events: none;
        user-select: none;
        a {
            pointer-events: all;
            text-decoration: none;
            color: $primaryDark;
            padding: 0.5rem 1.2rem;
            background: $primaryLight;
            border-radius: 30px;
            font-weight: 400;
            font-size: 0.8125rem;
            line-height: 1.125rem;
            text-align: center;
            margin-bottom: 1.5rem;
            &:hover{
                cursor: pointer;
            }
        }
        span{
            span{
                user-select: all;
            }

            &.textButton {
                cursor: pointer;
                pointer-events: all;
                margin-inline-start: 0.2rem;
            }
        }

    }
    .help-button {
        display: flex;
        justify-content: center;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: -0.02em;
        color: #3b4858;
        margin: 0 auto;
        margin-top: 1.5rem;
        border: none;
        text-align: center;
        text-decoration: none;
        font-family: 'Lato-Regular' !important;
        cursor: pointer;
        svg {
            height: 1rem;
            width: 1rem;
            margin-left: 7px;
            margin-right: 4px;
        }
        span {
            font-weight: 600;
        }

        &:hover {
            background: none;
        }
        &:focus {
            outline: none;
            background: none;
            border: none;
            box-shadow: none;
        }
    }
}

@include breakpoint-md() {
    .NoCustomersPage{
        padding: 2.125rem;
        .no-customers-background{
            width: auto;
            height: auto;
        }
        .no-customers-header{
            margin-top: 2.06rem;
            margin-bottom: 6.25rem;
        }
        .no-customers-title {
            font-size: 1.5rem;
            line-height: 1.8rem;
            margin-bottom: 3rem;
            & :nth-child(1) {
                margin-bottom: 0.5rem;
            }
        }
        .no-customers-sub-title {
            font-size: 1.25rem;
            line-height: 1.375rem;
            margin-bottom: 3rem;
        }
    }
}