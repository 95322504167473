@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";
@import "../../assets/styles/functions";

.InvoicesSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    padding: rem(20px);
    overflow-y: auto;
    font-size: 14px;
    & > * {
        z-index: $zIndexBase;
    }
    * {
        color: $primaryDark;
    }

    @include breakpoint-desktop-med() {
        padding: 2rem;
    }

    .FilteringSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        z-index: $zIndexHigherOrderElements;
        .FiltersBar {
        }
        .OtherOptionsContainer {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            .PaginationBtn {
            }

            @include breakpoint-desktop-med() {
                gap: 20px;
            }
        }
    }
    .ReadSelectedInvoicesStageTitle {
        font-size: 20px;
        font-weight: 700;
    }
    .InvoicesTableContainer {
        border-radius: 0.5rem;
        position: relative;
        min-height: 56px;
        overflow-y: auto;
        overflow-x: hidden;

        &.Empty {
            overflow: hidden;
        }
        .MuiTable-root.InvoicesTable {
            color: $primaryDark;
            position: relative;
            top: 0px;
            width: 100%;
            * {
                border: none;
            }
            .MuiTableRow-root {
                border-bottom: 1px solid $grey100;
                &.CollapsibleRow {
                    border: 0 inherit;
                    &:last-of-type {
                        border-bottom: none;
                    }
                }
                .MuiTableCell-root {
                    color: $primaryDark;
                }
            }
            .MuiTableHead-root {
                position: sticky;
                top: 0;
                background-color: $primaryDark;
                .MuiTableRow-root {
                    &.MuiTableRow-head {
                        &.InvoicesTableHeadRow {
                            .MuiTableCell-root.MuiTableCell-head {
                                &.EmptyTableHeadCell {
                                    width: 6.5%;
                                }
                                &.invoiceNumber {
                                    width: max(11vw, 160px);
                                }
                                &.fileNumber {
                                }
                                &.references {
                                    width: 12%;
                                }
                                &.totalAmount {
                                }
                                &.invoiceDate {
                                }
                                &.dueDate {
                                }
                                &.paymentStatus {
                                }
                                &.amountDue {
                                }
                                * {
                                    color: white;
                                    font-family: "Lato-Bold";
                                    text-wrap: nowrap;
                                }
                                .SortableColumn {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    .SortLabel {
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .MuiTableBody-root {
                .MuiTableRow-root {
                    &.MuiTableRow-hover {
                        background-color: $grey100;
                    }
                    &.MuiTableRow-selected {
                        background-color: $grey100;
                    }
                    &.InvoiceSkeletonRow {
                        .MuiTableCell-root {
                            &.InvoiceSkeletonRowCell {
                                .InvoiceSkeletonRowItemContainer {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 1rem;
                                }
                            }
                        }
                    }
                    .MuiTableCell-root {
                        &.MuiTableCell-body {
                            color: $primaryDark;
                            &.InnerTableRowCell {
                            }
                            &.OptionsCell {
                                padding: 12px;
                                .OptionsContainer {
                                    display: flex;
                                    justify-content: space-around;
                                    align-items: center;
                                    .ExpandRowButton {
                                        order: 1;
                                    }
                                    .IsSelectedInvoiceCheckboxContainer {
                                        order: 2;
                                    }
                                }
                            }
                            &.InvoiceNumberCell {
                                .InvoiceNumberDetailsContainer {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    column-gap: 1rem;
                                    .InvoiceTypeIcon {
                                        width: 26px;
                                    }
                                    .InvoiceNumber {
                                    }
                                }
                            }
                            &.FileNumberCell {
                                &.UnicargoFileNumber {
                                    cursor: pointer;
                                    text-decoration-line: underline;
                                    text-decoration-color: $primaryDark;
                                    text-underline-offset: 2px;
                                }
                            }
                            &.DueDateCell {
                                &.Overdue {
                                    color: $primaryRed;
                                }
                            }
                            &.ReferencesCell {
                                $referencesMaxWidth: 104px;
                                .ReferencesContainer {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    .ReferenceValue {
                                        max-width: $referencesMaxWidth;
                                        overflow-x: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        &:last-of-type {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                            &.AmountDueCell {
                                font-weight: 700;
                            }
                            &.PaymentStatusLabel {
                                .PaymentStatus {
                                    width: min-content;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 5px 10px;
                                    border-radius: 50px;
                                    background-color: $primaryLight;
                                    white-space: nowrap;
                                    &.Paid {
                                        background-color: $tagsPaid;
                                    }
                                    &.Unpaid {
                                        background-color: $tagsUnpaid;
                                    }
                                    &.PartiallyPaid {
                                        background-color: $tagsPartiallyPaid;
                                    }
                                    &.Credit {
                                        background-color: $tagsCredit;
                                    }
                                    &.Processing {
                                        background-color: $tagsProcessing;
                                    }
                                }
                            }
                            &.InvoiceDateCell {
                            }
                            &.InvoiceAmountCell {
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
            .ArrowIcon {
                transition: rotate 0.3s ease-in-out;
                &.Opened {
                    rotate: 90deg;
                }
            }
        }
        &.ShownPaymentBar {
            max-height: 80%;
        }
    }
    .ErrorTextContainer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .ErrorText {
            width: 100%;
            text-align: center;
        }
    }
    .InvoicesBackgroundSvg {
        align-self: flex-end;
        justify-self: center;
        position: absolute;
        bottom: 0;
        left: 15%;
        width: 75%;
        height: 50%;
        pointer-events: none;
        z-index: $zIndexBackgroundImage;
        @include breakpoint-sm() {
            background: none;
            left: 25%;
            width: 50%;
            height: 50%;
        }

        &.NoUnpaidInvoices {
            display: none;
        }
        &.NoMatchingInvoices {
            display: none;
        }
    }
    .EmptyStateContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: $zIndexBackgroundImage;
        .EmptyStateImageContainer {
            display: grid;
            grid-template-areas: "NoInvoicesLeftSvg CenterSvgContainer NoInvoicesRightSvg";
            align-items: center;
            width: 100%;
            height: 100%;
            overflow: hidden;
            @include breakpoint-xl() {
                .NoInvoicesLeftSvg {
                    transform: translateY(50%);
                }
                .NoInvoicesRightSvg {
                    transform: translateY(calc(-50% - 2rem));
                }
            }
            .NoInvoicesLeftSvg {
                order: 0;
                grid-area: NoInvoicesLeftSvg;
                width: 100%;
            }
            .CenterSvgContainer {
                order: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                grid-area: CenterSvgContainer;
                gap: 1rem;
                .CenterSvg {
                    order: 0;
                }
                .EmptyStateMessageView {
                    order: 1;
                }
                .EmptyStateBtn {
                    order: 2;
                    background: none;
                    border: none;
                    appearance: none;
                    text-decoration: underline;
                    color: $primaryDark;
                    text-underline-offset: 2px;
                    cursor: pointer;
                }
            }
            .NoInvoicesRightSvg {
                order: 2;
                grid-area: NoInvoicesRightSvg;
                width: 100%;
            }
        }
    }
    .PaymentAmountContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: $primaryDark;
        position: absolute;
        right: 0;
        bottom: 0;
        padding-inline-start: 2.5rem;
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
        &.ShowPaymentBar {
            opacity: 1;
            height: 56px;
        }
        .ClearSelectionBtnContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            .ClearSelectionBtnCross {
            }
            &:hover {
                .ClearSelectionBtn {
                    filter: brightness(1.5) contrast(0);
                }
            }
            .ClearSelectionBtn {
                text-decoration: 1px solid underline;
                text-decoration-color: $primaryDark;
                text-underline-offset: 2px;
                filter: brightness(1) contrast(1);
                transition: filter 0.1s ease-in-out;
            }
        }
        .BackToStageBtnContainer {
        }
        .TotalAmountToPayContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 2.5rem;
            min-width: 331px;
            height: 100%;
            padding: 0.8rem 2.75rem;
            border-top-left-radius: 100px;
            background-color: $primaryDark;
            .TotalAmountToPayText {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                color: $white;
                .Amount {
                    color: $white;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
    }
}

.ReferencesTooltip {
    display: flex;
    flex-direction: column;
    width: 100%;
    .ReferenceItem {
        &::before {
            content: "- ";
        }
    }
}

// root because the drawer acts as a portal (see 'react - createPortal' functino) and is located in the root element.
:root .drawer-container {
    .MuiPaper-root {
        width: 100%;
        @include breakpoint-sm() {
            width: 50%;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }
}
