@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.ShipmentsBlock {
    &.desktop {
        height: 100%;
        display: flex;
        flex-direction: column;

        :has(.empty-state) {
            .empty-state {
                justify-content: flex-end;
            }
        }
        .shipments-block-header-container {
            display: flex;
            position: relative;
            justify-content: flex-start;
            align-items: center;
            padding: 0.5rem;
            height: 60px;

            .header-container_message {
                align-items: baseline;
                padding: 0.5rem;
                font-weight: 500;
                line-height: 22px;
                &.new-shipment-modal {
                    border-radius: 6px;
                    z-index: 99999;
                    background-color: #eeeeee;
                    position: relative;
                }
            }
            .sub-header-shipments {
                padding-top: 2px;
                font-size: var(--font-size-title);
                color: var(--gray-medium);
            }
            .header-container_toggler {
            }
        }
        .shipments-block-body-container {
            height: 100%;
            padding: 0 0 0.5rem 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            overflow-y: auto;
            overflow-x: hidden;

            &.mobile {
                max-height: 450px;
            }
        }
        .shipments-block-footer-container {
            height: 50px;
            border-top: 1px solid var(--color-dividers);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 1rem;
        }
    }
    .ShipmentsLoadingErrorText {
        height: 100%;
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
    }
    .LoaderContainer {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .shipments-block-header-container {
            .HeaderLoader {
                width: 30%;
            }
        }
        .shipments-block-body-container {
            padding: 0 1rem;
            &.mobile {
                max-height: 450px;
            }
        }
        .shipments-block-footer-container {
            border-top: 1px solid $grey100;
            .FooterLoader {
                width: 10%;
            }
        }
    }
}

.triangle-modal {
    position: relative;
    left: -10px;
    top: 50%;
}
.name-shipment-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    height: 200px;
    .name-shipment-paragraph {
        margin: 0;
    }
    .text-container {
        font-size: 14px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        margin: 15px;
    }
    .name-shipment-bold {
        font-family: var(--font-bold);
    }
    .name-shipment-modal-footer {
        border-top: "1px solid #DCDCDC";
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        background-color: #f6f6f6;
        height: 50px;
    }
}
.shipments-block-header-container {
    .MuiDialog-paper {
        top: 20vh;
        .name-shipment-modal {
        }
    }
}

// root because the drawer acts as a portal (see 'react - createPortal' functino) and is located in the root element.
:root .drawer-container {
    &.mobile {
        .MuiPaper-root {
            width: 100%;
        }
    }
    .MuiPaper-root {
        width: 50%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
}
