@import "../../assets/styles/v2-styles/UiScheme.module.scss";

.SearchBar {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    background-color: $greyBackground;
    padding: 0 1rem;
    border-radius: 25px;
    color: $primaryDark;
    
    input{
        color: $primaryDark;
    }
    &.gray {
        background-color: $greyBackground;
    }
    &.white {
        background-color: white;
    }
    input {
        font-size: 14px;
        font-weight: 300;
        padding: 0;

        &.MuiInputBase-input::placeholder {
            color: $primaryDark;
        }
    }
    .SearchBarTextField {
        border: "1px solid transparent";
        height: "40px";
    }
    .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }
}
