.RFQBlock {
    background-image: url("../../../assets/images/rfq-background.png");
    border-radius: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1.5rem;
    height: 100%;
    &.Loading {
        background-image: none;
    }

    .message-container {
        font-size: 24px;

        .normal-text-container {
            color: var(--gray-white);
            font-size: 1em;
        }

        .bold-text-container {
            color: #defdbc;
            font-size: 1.3em;
            font-family: var(--font-black);
            line-height: 2.3rem;
        }
    }

    .rfq-block-button {
        width: fit-content;
        background-color: #06cea8;
        border-radius: 36px;
        :hover {
            color: #1d4650;
        }
    }
    .LoaderContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        gap: 2rem;
        .LeftContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 12px 0;
            .TopLeftContainer {
                display: flex;
                flex-direction: column;
                gap: 11px;
                .LeftLoader1 {
                    width: 100%;
                }
                .LeftLoader2 {
                    width: 60%;
                }
            }
            .LeftLoader3 {
                height: 2rem;
                width: 50%;
            }
        }
        .RightLoader1 {
            height: 100%;
            border-radius: 4px;
        }
    }
}
