@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.InvoicesInnerTable {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    * {
        white-space: nowrap;
    }
    span {
        overflow: hidden;
    }
    .InnerTableSectionsLineSeparator {
        height: 100%;
        order: 1;
    }
    .IdTable {
        order: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
        height: 100%;
        flex-grow: 1;
        .FileInfoContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 1.5rem;
            .FileInfoTitle {
                font-size: 20px;
            }
            .FileInfoItemsContainer {
                display: grid;
                grid-template-columns: 1fr;
                justify-content: flex-start;
                align-items: flex-start;
                row-gap: 1rem;
                .FileInfoItemKey {
                    width: min-content;
                }
                .FileInfoItemValueContainer {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    column-gap: 0.5rem;
                    .FileInfoItemValueContent {
                        white-space: normal;
                        max-width: 250px;
                    }
                }
                @include breakpoint-lg() {
                    grid-template-columns: 0.5fr 1fr;
                    column-gap: 2rem;
                }
            }
        }
        .IdTableLineSeparator {
            width: 100%;
            height: 2px;
        }
        .ReferencesContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 1.5rem;
            .ReferencesItemsContainer {
                display: grid;
                grid-template-columns: 1fr;
                justify-content: flex-start;
                align-items: flex-start;
                row-gap: 0.25rem;
                .ReferenceItem {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: 0.5rem;
                    .ReferenceItemKey {
                        width: min-content;
                    }
                    .ReferenceItemValue {
                        max-width: 250px;
                    }
                }
                @include breakpoint-lg() {
                    grid-template-columns: 1fr 1fr;
                    column-gap: 2rem;
                }
            }
        }
    }
    .ChargeTable {
        order: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        row-gap: 2rem;
        min-width: 50%;
        .LineItemsSection {
            display: grid;
            row-gap: 1.5rem;
            width: 100%;
            .LineItemsTitle {
                font-size: 20px;
            }
            .LineItemsContainer {
                display: grid;
                justify-content: flex-start;
                align-items: flex-start;
                row-gap: 1rem;
                column-gap: 1rem;
            }
        }
        .NoteContainer {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            column-gap: 1rem;
            .NoteTitle {
            }
            .NoteContent {
                max-width: 100%;
                white-space: normal;
            }
        }
        .DocumentsContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 1rem;
            .DocumentsTitle {
                font-size: 20px;
            }
            .DocumentsItemsContainer {
                display: grid;
                grid-template-columns: 1fr;
                justify-content: space-between;
                align-items: flex-start;
                row-gap: 1rem;
                .DocumentItem {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: 0.5rem;
                    cursor: pointer;
                    &:hover {
                        .DocumentTitle {
                            text-decoration: 1px underline $primaryDark;
                            text-underline-offset: 4px;
                        }
                    }
                    &.InvoiceDocument {
                    }
                    .DocumentIcon {
                    }
                    .DocumentTitle {
                        max-width: 250px;
                    }
                }
                @include breakpoint-lg() {
                    grid-template-columns: 1fr 1fr;
                    column-gap: 2rem;
                    .DocumentItem {
                    }
                }
            }
        }
    }
}
