@import "../../../assets/styles/mixins";
@import "../../../assets/styles/functions";
@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.NewsBlock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;
    width: 100%;
    position: relative;
    height: 100%;
    gap: 1rem;
    .NewsArticleHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .CardTitle {
            font-size: rem(16px);
            color: $primaryDark;
        }
        .SeeAllContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            .SeeAllText {
                color: $primaryDark;
            }

            .children-container {
                font-size: rem(12px);

                @include breakpoint-desktop-med() {
                    font-size: rem(14px);
                }
            }
        }
    }
    .NewsArticleBody {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-grow: 1;
        gap: 1rem;
        overflow-x: hidden;
        border-radius: 12px 12px 0 0;
        width: 100%;
        .ImageAndDescContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 1rem;
            cursor: pointer;
            width: 100%;

            .ArticleImage {
                display: block;
                width: 100%;
                height: 150px;
                object-fit: cover;
                border-radius: 12px;
                @include breakpoint-xl {
                    height: 250px;
                }
            }
            .ArticleTitle {
                width: 100%;
                color: $primaryDark;
                font-size: rem(14px);
                line-height: 1rem;
            }
            .ArticleDescription {
                width: 100%;
                font-size: 14px;
                color: $grey300;
                @include breakpoint-lg-max {
                    display: none;
                }
            }
        }
    }
    .ImagePaginationArrow {
        cursor: pointer;
        &.Previous {
            rotate: 180deg;
        }
    }
    .MuiButtonBase-root{
        .arrowNext{
                justify-content: flex-end;
            }
    }
    .ImagePaginationStepper {
     padding: 0px;
        justify-content: space-between;
        .MuiButtonBase-root {
            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
        .MuiMobileStepper-dots{
            display: none;
        }
         .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.arrowNext.css-1eyd0l1-MuiButtonBase-root-MuiButton-root {
         justify-content: flex-end;
        padding: 0px;
        }
        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-1eyd0l1-MuiButtonBase-root-MuiButton-root {
         justify-content: flex-start;
        padding: 0px;
        }
    }
    .MobilePaginationContainer {
        .MuiButtonBase-root {
            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }
    .MobilePaginationContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    .LoaderContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 1.5rem;
        .TitleAndLinkLoader {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .ArticleTitleDescriptionsLoader {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-grow: 1;
            gap: 1rem;
            .DescriptionLoadersContainer {
                width: 100%;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 0.5rem;
            }
        }
    }
}