// UiScheme.scss
// This file allows for sharing of variables between scss and ts files

// Colors
$primaryDark: #1d4650;
$primaryLight: #06cea8;
$secondaryLight: #00dbb2;
$primaryBackground: #fff;
$inTransitMedium: #abe01c;
$inTransitDark: #80a02a;
$primaryRed: #bf3b3b;
$secondaryRed: #ffa0a0;
$secondaryGreen: #d1ef54;
$secondaryLime: #defdbc;
$destinationPurple: #c1b7ed;
$destinationMedium: #8c7ed4;
$destinationDark: #6151c0;
$originLightBlue: #cae5e2;
$originMedium: #a7ceca;
$originDark: #769d99;
$grey100: #dcdcdc;
$grey200: #bdc1c2;
$grey300: #778083;
$greyBackground: #f3f3f3;
$white: #fff;

$tagsUnpaid: #ffd0d0;
$tagsPartiallyPaid: #ffd0d0;
$tagsPaid: #b4f0e4;
$tagsCredit: #b4f0e4;
$tagsProcessing: #ece9f9;

// Font Sizes
$fontSizes-h2: 20px;
$fontSizes-p: 14px;
$fontSizes-mainPrices: 22px;
$fontSizes-secondaryPrices: 16px;

// Screen Widths
$mobileMinWidth: 0px;
$tabletMinWidth: 700px;
$desktopMinWidth: 1194px;
$desktopMedWidth: 1440px;
$desktopLargeMinWidth: 1900px;

// Z-Indexes
$zIndexBackground: -1;
$zIndexBackgroundImage: 1;
$zIndexBase: 2;
$zIndexHigherOrderElements: 3;
$zIndexPopups: 4;

:export {
    primaryDark: $primaryDark;
    primaryLight: $primaryLight;
    secondaryLight: $secondaryLight;
    primaryRed: $primaryRed;
    primaryBackground: $primaryBackground;
    secondaryRed: $secondaryRed;
    secondaryGreen: $secondaryGreen;
    secondaryLime: $secondaryLime;
    destinationPurple: $destinationPurple;
    originLightBlue: $originLightBlue;
    originMedium: $originMedium;
    originDark: $originDark;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    greyBackground: $greyBackground;
    white: $white;
    tagsUnpaid: $tagsUnpaid;
    tagsPartiallyPaid: $tagsPartiallyPaid;
    tagsPaid: $tagsPaid;
    tagsCredit: $tagsCredit;
    tagsProcessing: $tagsProcessing;
    fontSizesH2: $fontSizes-h2;
    fontSizesP: $fontSizes-p;
    fontSizesMainPrices: $fontSizes-mainPrices;
    fontSizesSecondaryPrices: $fontSizes-secondaryPrices;
    mobileMinWidth: $mobileMinWidth;
    tabletMinWidth: $tabletMinWidth;
    desktopMinWidth: $desktopMinWidth;
    desktopLargeMinWidth: $desktopLargeMinWidth;
    zIndexBackground: $zIndexBackground;
    zIndexBackgroundImage: $zIndexBackgroundImage;
    zIndexBase: $zIndexBase;
    zIndexHigherOrderElements: $zIndexHigherOrderElements;
    zIndexPopups: $zIndexPopups;
    destinationMedium: $destinationMedium;
    destinationDark: $destinationDark;
    inTransitMedium: $inTransitMedium;
    inTransitDark: $inTransitDark;
    desktopMedWidth: $desktopMedWidth;
}
