@import "../../assets/styles/v2-styles/UiScheme.module.scss";

.SortIconComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    &.NONE {
    }
    &.ASC {
        transform: rotate(180deg);
        svg {
            path {
                fill: $primaryLight;
            }
        }
    }
    &.DESC {
        svg {
            path {
                fill: $primaryLight;
            }
        }
    }
    .SortIconSvg {
    }
}
