@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.my-route-container {
    border: 1px solid $greyBackground;
    border-radius: 10px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding: 20px 0 20px 40px;
    margin: 0 0.5rem;
    height: 100%;
    overflow-y: auto;

    background-image: url("../../assets/icons/documents-decorative-top-right.svg");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: top right;

    .pill-container{
        display: none;

        @include breakpoint-desktop-med(){
            display: flex;
        }
    }

    .accordion-cards-and-stepper-and-icon-container {
        z-index: 0;
        display: flex;
        justify-content: space-between;
        position: relative;

        .accordion-cards-and-stepper-container {
            display: flex;
            flex: 2;
            gap: 1rem;

            @include breakpoint-lg-max {
                flex: unset;
                width: 90%;
            }

            .accordion-stepper-container {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-top: 20px;
                padding-bottom: 100px;
                z-index: 1;
                @include breakpoint-md {
                    width: 100%;
                }
                @include breakpoint-md-max {
                    width: 100%;
                }
            }
        }
        .booking-card-container {
            flex: 1;
        }
        .icon {
            z-index: -1;
            position: absolute;
            right: 0;
            top: -50px;
            @include breakpoint-lg-max {
                top: 0;
            }
        }
    }

    @include breakpoint-lg-max {
        padding: 0 0 20px 20px;
    }
}
