@import "../../../assets/styles/mixins";
@import "../../../assets/styles/functions";
@import "../../../assets/styles/style.scss";

.AssistanceBlock {
    width: 100%;
    padding: 0.5rem;
    display: flex;

    & > div {
        padding: 0.25rem 0;
    }

    .assistance-content-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;

        @include breakpoint-desktop-med() {
            gap: 10px;
        }

        .logo-container {
            display: flex;
        }

        .assistance-message-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: #1d4650;

            .title {
                font-size: var(--font-size-title);
                font-family: var(--font-bold);
            }

            .content {
                font-size: var(--font-size-text);
                letter-spacing: -0.24px;
            }
        }
    }
    .LoaderContainer {
        width: 100%;
        display: grid;
        grid-template-areas:
            "LeftCircle TopCenterLine RightLine"
            "LeftCircle BottomCenterLine RightLine";
        grid-template-columns: 1fr 8fr 3fr;
        justify-content: flex-start;
        align-items: center;
        row-gap: 0.5rem;
        column-gap: 1.2rem;
        .LeftCircle {
            width: 1.5rem;
            height: 1.5rem;
            grid-area: LeftCircle;
        }
        .TopCenterLine {
            width: 50%;
            grid-area: TopCenterLine;
        }
        .BottomCenterLine {
            width: 60%;
            height: 0.75rem;
            grid-area: BottomCenterLine;
        }
        .RightLine {
            grid-area: RightLine;
        }
    }

    .assistance-button-container {
        border-left: 1px solid var(--gray-light);
        display: flex;
        align-items: center;

        .MuiButtonBase-root {
            padding: 0 0 0 1rem;

            .children-container {
                font-size: rem(12px);

                @include breakpoint-desktop-med() {
                    font-size: rem(14px);
                }
            }
        }
    }

    @include breakpoint-desktop-med() {
        padding: rem(10px) rem(9px);
    }
}
