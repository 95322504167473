.SkeletonShipmentTab{
    display: flex;
    flex-direction: row;
    gap: 4rem;
    padding: 20px 32px 0.75rem 32px;
}

.LeftItem{
    height: 15px;
    width: 100px;
}

.RightItem{
    height: 15px;
    width: 300px;
}