.PaymentFormPageIFrameContainerView {
    width: 100%;
    height: 669px;
    display: flex;
    justify-content: center;
    align-items: center;
    .PaymentFormPageIframe {
        width: 95%;
        height: 95%;
        border: none;
        border-radius: inherit;
    }
}
