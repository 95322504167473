@import "../../../../../../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../../../../../../assets/styles/mixins";

.root {
    border: 1px solid $grey100;
    border-radius: 8px;
    margin: 1rem 1.5rem 1.125rem;
    height: 100%;
    padding: 1.5rem;
    position: relative;
    overflow: auto;
    width: inherit;

    background-image: url("../../../../../../../assets/icons/documents-decorative-top-right.svg");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: top right;

    @include breakpoint-xl {
        padding: 1.875rem 1.875rem 1.5rem 1.875rem;
        margin: 1rem 1.6875rem 1.125rem;
    }
}

.decorativeTopRight {
    position: absolute;
    top: 0;
    right: 0;
}

.shipmentInfoContainer, .cargoDetailsContainer {
    .containerTitle {
        margin: 0;
        padding-left: 1.25rem;
        padding-bottom: 0.9375rem;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        font-weight: 700;
    }
    .shipmentInfoBoxContainer, .cargoDetailsBoxContainer {
        width: 100%;
        border: 1px solid $grey100;
        border-radius: 10px;
        height: fit-content;
        background-color: $white;
        position: relative;
        padding: 1.5rem;

        .FileInfoContainer {
            font-size: $fontSizes-p;
            width: inherit;
    
            .FileInfoItemsContainer {
                display: grid;
                grid-template-columns: 1.5fr 1fr;
                column-gap: 1.5rem;
                row-gap: 2rem;
                line-height: normal;
                width: inherit;
                .fieldContainer, .containerNumber {
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                    max-height: 100%;
                    overflow: auto;

                    .FileInfoItemKey {
                        max-width: max-content;
                        font-weight: 700;
                        color: $primaryDark;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .FileInfoItemValueContainer {
                        display: flex;
                        column-gap: 0.5rem;

                        .countryFlag {
                            margin-top: 2px;
                        }
                        .FileInfoItemValueContent {
                            font-weight: 400;
                            white-space: normal;
                            max-width: 150px;
                            text-transform: capitalize; 
                            color: $grey300;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            .containerNumberItem {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .moreIndicator {
                                color: $primaryDark;
                                text-decoration: underline;
                                text-transform: lowercase;
                            }

                            @media (min-width: 1600px) {
                                max-width: 200px;
                            }
                        }

                        .breakWord {
                            text-wrap: wrap;
                        }
                    }
                }

                @include breakpoint-md() {
                    grid-template-columns: 1fr 1fr 1fr;
                    row-gap: 2rem;
                }
                @include breakpoint-xl() {
                    column-gap: 2rem;
                }
            }
        }

        @include breakpoint-lg() {
            padding: 2rem 2rem 2.1875rem;
        }
    }
    .shipmentInfoBoxContainer {
        margin-bottom: 24px;
    }
    .cargoDetailsBoxContainer {
        height: fit-content;
        .FileInfoContainer {
            font-size: $fontSizes-p;
            width: inherit;
    
            .FileInfoItemsContainer {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                row-gap: 2rem;
                width: inherit;

                @include breakpoint-md() {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-rows: 1fr 1fr;
                    row-gap: 2rem;

                    // Disable it in case we need to order by rows
                    & > :nth-child(2n + 1){
                        grid-row: 1;
                    }
                    & > :nth-child(2n){
                        grid-row: 2;
                    }
                    
                    & > .containerNumber{
                        grid-row: 1 / -1;
                    }
                }

                @include breakpoint-xl() {
                    column-gap: 2rem;
                }
            }
        }
    }
}