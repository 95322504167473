@import "../../../../assets/styles/v2-styles/UiScheme.module.scss";

.NotificationCardBase {
    border: 1px solid var(--gray-light);
    width: 100%;
    &.desktop {
        height: 63px;

        .notifications-inner-container {
            display: flex;
            justify-content: space-between;
            height: 100%;

            .notification-card_status-image {
                .green-lines {
                    height: 100%;
                    border-radius: 0px 8px 8px 8px;
                }
                .red-lines-and-rectangle {
                    height: 100%;
                }
            }
            .notification-details-container {
                display: flex;
                width: 100%;
                .notification-card_details-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 0.5rem 1.5rem 0.5rem 1rem;
                    width: 100%;

                    .top-container {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        .notification-message {
                            font-size: $fontSizes-p;
                            color: $primaryDark;
                            white-space: nowrap;
                        }

                        // test
                        .date {
                            color: $grey300;
                            font-size: var(--font-size-text-small);
                            line-height: 11px;
                            letter-spacing: -0.02em;
                        }
                    }

                    .bottom-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 0.5rem;

                        .shipment-name {
                            color: $grey300;
                            font-size: $fontSizes-p;
                        }
                        .details-inner-container {
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                            font-size: var(--font-size-text);
                            white-space: nowrap;
                            line-height: 14px;

                            .previous-message {
                                color: var(--gray-light);
                            }
                            .notification-arrow-icon {
                            }
                            .current-message {
                                color: $primaryDark;
                            }
                        }
                    }
                }
            }
            .approval-button-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 70px;
            }
        }
    }

    &.mobile {
        .notification-card-header-container {
            padding: 1rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            // background-color: in the parent element

            .header_icon {
                display: flex;
            }
            .header_title {
                color: var(--gray-white);
            }
        }
        .notification-card-content-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            padding: 1rem;

            .content_title-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .title-container_title {
                    color: var(--gray-dark);
                    font-size: var(--font-size-title-large);
                }

                .title-container_date {
                    color: var(--gray-light);
                    font-size: var(--font-size-text);
                }
            }
            .content_details-container {
                font-size: var(--font-size-title);
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                .details-container_previous-message {
                    color: var(--gray-light);
                }
                .details-container_current-message {
                    color: var(--gray-dark);
                }
            }
        }
        .notification-card-footer-container {
            padding: 1rem 0;
            margin: 0 1rem;
            border-top: 1px solid var(--gray-light);
            .footer_button-container {
                .button-container_button {
                    text-decoration: underline;
                    width: 100px;

                    .text {
                        line-height: 19px;
                        white-space: nowrap;
                        color: var(--gray-dark);
                        border-bottom: 1px solid var(--gray-dark);
                    }

                    .fade {
                        display: flex;
                        justify-content: flex-start;
                        opacity: 1;
                        transition: opacity 0.3s ease-out;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    .fade.hidden {
                        opacity: 0;
                    }
                }
            }
        }
    }
}
