@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.MuiModal-root.PaymentPopupModal {
    .MuiBox-root {
        width: 626px;
        max-height: 90vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0px 4px 12px 0px #555;
        transition: width 0.5s, height 0.5s;
        overflow-y: hidden;
        .CloseBtnContainer{
            align-self: flex-start;
            padding: 0.25rem 0.75rem;
            cursor: pointer;
        }
        .SummaryDescriptionTitle {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $fontSizes-h2;
            font-family: Lato-Bold;
        }
        .PaymentMethodsButtonsContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 16px;
        }
    }
}
