@import "../../assets/styles/v2-styles/UiScheme.module.scss";

$selectBtnHeight: 3rem;

.UnicargoSelectBtn2 {
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: $selectBtnHeight;
    border-radius: 1rem;
    border: 1px solid transparent;
    background-color: white;
    box-shadow: 0px 4px 12px 0px #00000014;
    width: 100%;
    .SelectContainer {
        height: 100%;
        width: 100%;
        background: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        color: $primaryDark;
        border-radius: 1.5rem;
        border: 1px solid transparent;
        padding: 5.5px 8px;
        cursor: pointer;
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none; /* Firefox all */
        user-select: none;
        font-size: 14px;
        .SelectContentContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .SelectLabel {
                color: $grey200;
            }
            .SelectValue {
            }
        }
        .ArrowIconContainer {
            padding: 0;
            .ArrowIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                path {
                    transition: fill 0.2s ease-in-out;
                    transition: scale 0.2s ease-in-out;
                    transform-origin: center;
                    fill: $primaryDark;
                    scale: 1;
                }
                &.Opened {
                    path {
                        scale: 1.2;
                    }
                }
            }
        }
    }
    .OptionsMenu {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 100%;
        top: calc($selectBtnHeight + 0.5rem);
        padding: 0.5rem 1rem;
        background-color: white;
        border-radius: 1rem;
        border: 1px solid transparent;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        justify-content: center;
        opacity: 0;
        transform-origin: top;
        overflow: hidden;
        z-index: $zIndexHigherOrderElements;
        &:not(.Opened) {
            display: none;
        }
        &.Opened {
            opacity: 1;
        }
        .LineSeparator {
            margin: 0.5rem 0;
        }
        .OptionContainer {
            width: 100%;
            height: 2rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;
            padding: 2px;
            font-size: 12px;
            &.SingleChoiceSelect {
                cursor: pointer;
                padding: 0.25rem;
                &:hover {
                    border-radius: 0.25rem;
                    background-color: $greyBackground;
                }
            }
        }
    }
}
