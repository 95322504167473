@import "./v2-styles/UiScheme.module.scss";

// Breakpoints
@mixin breakpoint-sm() {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin breakpoint-md-max() {
    @media (max-width: calc($tabletMinWidth - 1px)) {
        @content;
    }
}

@mixin breakpoint-md() {
    @media (min-width: $tabletMinWidth) {
        @content;
    }
}

@mixin breakpoint-lg() {
    @media (min-width: $desktopMinWidth) {
        @content;
    }
}

@mixin breakpoint-lg-max() {
    @media (max-width: $desktopMinWidth) {
        @content;
    }
}

@mixin breakpoint-desktop-med() {
    @media (min-width: $desktopMedWidth) {
        @content;
    }
}

@mixin breakpoint-desktop-med-max() {
    @media (max-width: $desktopMedWidth) {
        @content;
    }
}

@mixin breakpoint-xl() {
    @media (min-width: $desktopLargeMinWidth) {
        @content;
    }
}

@mixin breakpoint-between-desktops($min-width, $max-width) {
    @media (min-width: $min-width) and (max-width: $max-width) {
        @content;
    }
}
