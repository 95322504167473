@import "../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../assets/styles/mixins";

.estimate {
    color: $grey100;
}

.accordion-container-disabled {
    background-color: white !important;
}
// TODO: CHECK MOBILE AFTER THIS COMMIT
.accordion-container {
    width: inherit !important;
    max-width: 525px;
    border: 1px solid $grey100;
    box-shadow: none !important;
    border-radius: 8px !important;
    padding-top: 5px;
    padding-bottom: 5px;

    @include breakpoint-md-max {
        width: 90%;
    }

    @include breakpoint-lg {
        width: 25vw;
    }

    &:before {
        display: none;
    }

    .title-container {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        font-size: 18px;

        .inland-title {
            color: $grey300;
            font-size: 12px;
        }

        @include breakpoint-md-max {
            font-size: 16px;
        }
        .sub-title {
            color: $grey300;
            font-size: 12px;
            @include breakpoint-md-max {
                font-size: 10px;
            }
        }
    }

    .accordion-summary-container {
        padding-left: 26px;
        height: 4rem;
    }

    .inland-accordion-summary-container {
        padding-left: 26px;
        height: 2rem !important;
        min-height: 2rem !important;
        padding-top: 5px;
    }

    .accordion-details-container {
        .accordion-primary-details {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            padding-bottom: 1.4rem;
            padding-left: 20px;
            flex-wrap: wrap;
            padding-right: 10px;

            .icon-container {
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .detail-title {
                font-size: 12px;
                flex-wrap: wrap;
                @include breakpoint-md-max {
                    font-size: 10px;
                }
            }

            .detail-date {
                font-size: 12px;
                color: $grey300;
                @include breakpoint-md-max {
                    font-size: 10px;
                }
            }
        }

        .last-primary-detail {
            margin-bottom: 0.6rem;
        }

        .accordion-primary-details {
            color: $primaryDark;
        }

        .accordion-primary-details.disable {
            color: $grey200;
        }

        .step-container {
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            margin-bottom: 2rem;
            padding-right: 10px;
            color: $primaryDark;
        }
    }

    .separator-line {
        height: 1px;
        background-color: $grey100;
        border: none;
        margin-bottom: 1.4rem;
    }

    .step-dot {
        width: 16px;
        height: 16px;
        left: -53px;
        position: absolute;
        background-color: $grey200;
        border-radius: 50%;

        &::before {
            content: "";
            position: absolute;
            left: 26px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 1px;
            background-color: $grey200;
        }
    }

    .title-dot {
        transform: translateY(50%);
    }
}
