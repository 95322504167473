@import "../../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../../assets/styles/mixins";

.header {
    height: 90px;
    display: flex;
    align-items: center;
    .pill-container {
        height: 56px;
        margin-left: 40px;
        .logo-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            .arrow-icon {
                cursor: pointer;
            }
        }

        @include breakpoint-lg {
            display: flex;
        }
    }

    .steps-container {
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 0px auto;
        width: 100%;
        justify-content: flex-start;
        .selected-step {
            color: white;
        }
        .step {
            color: var(--gray-light);
        }
    }
}

.mobile-header-container {
    position: fixed;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .layout{
        background-color: $primaryDark;
        padding: 18px 24px;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        width: 100%;

        .back-cta{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: fit-content;
            width: 100%;
    
            .arrow-icon-mobile {
                height: 14px;
                width: 14px;
                margin-right: 16px;
            }
    
            .unicargo-logo-mobile{
                height: 24px;
                width: 24px;
            }
        }
        .mobile-text-header-container {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: visible;
            color: white;
        }
    }
}