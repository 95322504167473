.SkeletonDocumentTabContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1.5rem 1.5rem;
    overflow: auto;
    border: 1px solid var(--gray-light);
    border-radius: 16px;
}

.ItemContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:8px;
        padding: 1rem;
        width: 100%;
        height: initial;
        border-bottom: 1px solid var(--gray-light);
        border-radius: 0px;

        &:last-child {
            border-bottom: none;
        }
}

.skeleton {
    width: 200px;
    height: 1rem;
}
