@use "./mixins";
@use "./functions";
@import "../fonts/style.scss";

:root {
    /* Font Families */
    --font-regular: "Lato-Regular";
    --font-regular-italic: "Lato-Italic";
    --font-thin: "Lato-Thin";
    --font-thin-italic: "Lato-ThinItalic";
    --font-light: "Lato-Light";
    --font-bold: "Lato-Bold";
    --font-bold-italic: "Lato-BoldItalic";
    --font-black: "Lato-Black";
    --font-black-italic: "Lato-BlackItalic";
    --font-semi-bold: "Lato-SemiBold";
    --font-semi-bold-italic: "Lato-SemiBoldItalic";

    /* Typography */
    // Texts font size
    --font-size-title-large: 16px;
    --font-size-title: 14px;
    --font-size-text: 12px;
    --font-size-text-small: 10px;

    /* Gaps and Spaces */
    --default-gap: 1.5rem;

    /* Colors */
    // Neutrals (backgrounds & dividers)
    --color-dividers: #dcdcdc;
    --color-dashboard-bg: #f3f3f3;
    --components-bg-color: #ffffff;

    // Texts & Icons
    --gray-dark: #3b4858;
    --gray-medium: #777c83;
    --gray-light: #bdbfc2;
    --gray-white: #ffffff;
    --gray-scrollbar: #babac0;

    // System (mainly tags)
    --color-origin: #fdbb00;
    --color-in-transit: #008fff;
    --color-destination: #06cea8;
    --color-destination-light: #e4fbf6;
    --color-error: #f47a36;

    // General Settings
    html,
    body,
    #root {
        padding: 0;
        margin: 0;
        color: #3b4858; // global color
        overflow-x: hidden;
    }

    // custom scrollbar
    *::-webkit-scrollbar {
        background-color: transparent;
        width: 16px;
    }
    *::-webkit-scrollbar-thumb {
        background-color: var(--gray-scrollbar);
        border-radius: 16px;
        border: 5px solid #fff;
        height: 60px;
    }
    *::-webkit-scrollbar-track {
        background-color: transparent;
    }

    // h_ elements
    h1 {
        font-size: 24px;
        margin: 0;
    }

    h2 {
        font-size: 18px;
        margin: 0;
        font-weight: 300;
    }
    .no-select {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
    }

    .Bold {
        font-family: "Lato-Bold";
    }

    /* Global font-family */
    font-family: var(--font-regular), sans-serif;
}
