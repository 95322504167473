@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.SkeletonLoaderItem {
    height: 1rem;
    width: 100%;
    border-radius: 100px;
    background: $greyBackground;
    &.Bar {
    }
    &.Circle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
    }
    &.Loading {
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
