@import "../assets/styles/mixins";

.auth-container {
    .amplify-text.amplify-field__description {
        font-style: normal !important;
        padding-left: 22px !important;
    }
    .terms-and-condition-link {
        text-decoration: underline !important;
        cursor: pointer !important;
        &:hover {
            color: grey !important;
        }
    }

    height: 100vh;
    width: 100vw;

    position: relative;
    padding: 1.125rem;
    overflow: scroll;
    .auth-background {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 200px;
        height: 230px;
        pointer-events: none;
        user-select: none;
    }
    .auth-header {
        width: 100%;
        text-align: center;
        margin-bottom: 3.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        & .back-btn {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    [data-amplify-authenticator] {
        [data-amplify-container] {
            [data-amplify-router] {
                border: none;
                box-shadow: none;
                [role="tablist"] {
                    display: none;
                }
                .auth-message {
                    color: $primaryDark;
                    text-align: center;
                    margin-bottom: 2rem;
                    pointer-events: none;
                    user-select: none;
                    .title {
                        display: block;
                        font-weight: 700;
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                        margin-bottom: 0.25rem;
                    }
                    .description {
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 1.25rem;
                    }

                    &.reset-password {
                        margin-bottom: 1rem;
                    }
                }
                form {
                    max-width: 450px;
                    padding: 0;
                    margin: 0 auto;
                    margin-bottom: 0.5rem;
                  
                    [data-amplify-sign-up-errors] {
                        + .amplify-textfield {
                            .amplify-field-group {
                                position: absolute;
                                top: calc(50% - 3px);
                            }
                        }
                    }
                    .amplify-field.amplify-textfield {
                        position: relative;
                        &.amplify-passwordfield:nth-child(3) {
                            + .amplify-textfield {
                                .amplify-field-group {
                                    position: absolute;
                                    top: calc(50% - 3px);
                                }
                            }
                        }
                        .amplify-label {
                            font-size: 14px;
                            user-select: none;
                        }
                        .amplify-input {
                            height: 48px;
                            border: none;
                            border-radius: 16px;
                            box-shadow: 0px 4px 12px 0px #00000014;
                            &[name="password"],
                            &[name="confirm_password"] {
                                border: none;
                                border-radius: 16px 0 0 16px;
                                box-shadow: none;
                            }
                        }
                        .terms,
                        .newsletter {
                            width: 14px;
                            height: 14px;
                            padding: 0;
                            margin: 0;
                            border-radius: 0;
                            border: 1px solid #bdbfc2;
                            display: grid;
                            place-content: center;
                            &::before {
                                content: "";
                                width: 10px;
                                height: 10px;
                            }
                            &:checked {
                                box-shadow: inset 10px 10px black;
                                clip-path: polygon(
                                    14% 44%,
                                    0 65%,
                                    50% 100%,
                                    100% 16%,
                                    80% 0%,
                                    43% 62%
                                );
                                // border: 1px solid #BDBFC2;
                                // box-shadow: inset 10px 10px black;
                                // clip-path: spolygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                            }
                        }
                    }
                    .amplify-passwordfield {
                        box-shadow: 0px 4px 12px 0px #00000014;
                        border-radius: 16px;

                        .amplify-button {
                            &[aria-label="Show password"] {
                                border: none;
                                border-top-right-radius: 16px;
                                border-bottom-right-radius: 16px;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                &:hover {
                                    background: none;
                                }
                                &:focus {
                                    outline: none;
                                    background: none;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    .amplify-button {
                        &[type="submit"] {
                            position: relative;
                            visibility: hidden;
                            &:after {
                                border-left: none;
                                color: $primaryDark;
                                background: $primaryLight;
                                border-radius: 30px;
                                font-size: 13px;
                                margin: 0 auto;
                                visibility: visible;
                                position: absolute;
                                padding: 5px 16px;
                                font-weight: 600;
                                text-align: center;
                                margin-top: 2.5rem;
                                &:hover {
                                    background: #202a36;
                                }
                            }
                        }
                    }
                    &[data-amplify-authenticator-signin] {
                        .amplify-button {
                            &[type="submit"] {
                                &:after {
                                    content: "Log in";
                                }
                            }
                        }
                    }
                    &[data-amplify-authenticator-signup] {
                        p.amplify-text--error {
                            font-size: 0.875rem;
                            color: $primaryRed;
                        }
                        .amplify-button {
                            &[type="submit"] {
                                &:after {
                                    content: "Sign up";
                                }
                            }
                        }
                    }
                    &[data-amplify-authenticator-resetpassword] {
                        h3.amplify-heading {
                            color: $primaryDark;
                            font-size: 1.5rem;
                            text-align: center;
                            pointer-events: none;
                            user-select: none;
                            margin-bottom: 2.7rem;
                        }
                        .amplify-button {
                            &[type="submit"] {
                                &:after {
                                    content: "Send code";
                                }
                            }
                            &.amplify-button--link {
                                display: block;
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1rem;
                                letter-spacing: -0.02em;
                                color: $primaryDark;
                                border: none;
                                margin: 1.5rem auto 0;
                                &:hover {
                                    background: none;
                                }
                                &:focus {
                                    outline: none;
                                    background: none;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    &[data-amplify-authenticator-forcenewpassword] {
                        h3.amplify-heading {
                            color: #3b4858;
                            text-align: center;
                            pointer-events: none;
                            user-select: none;
                        }
                        .amplify-button {
                            &[type="submit"] {
                                &:after {
                                    content: "Change Password";
                                    width: auto;
                                }
                            }
                            &.amplify-button--link {
                                display: block;
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1rem;
                                letter-spacing: -0.02em;
                                color: #3b4858;
                                border: none;
                                margin: 0 auto;
                                &:hover {
                                    background: none;
                                }
                                &:focus {
                                    outline: none;
                                    background: none;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    &[data-amplify-authenticator-confirmresetpassword] {
                        h3.amplify-heading {
                            color: $primaryDark;
                            font-size: 1.5rem;
                            text-align: center;
                            pointer-events: none;
                            user-select: none;
                        }
                        .amplify-button {
                            &[type="submit"] {
                                &:after {
                                    content: "Submit";
                                    width: auto;
                                }
                            }
                            &.amplify-button--link {
                                display: block;
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1rem;
                                letter-spacing: -0.02em;
                                color: $primaryDark;
                                border: none;
                                margin: 0 auto;
                                &:hover {
                                    background: none;
                                }
                                &:focus {
                                    outline: none;
                                    background: none;
                                    box-shadow: none;
                                }
                            }
                        }
                        p.amplify-text--error {
                            font-size: 0.875rem;
                            color: $primaryRed;
                        }
                    }
                    &[data-amplify-authenticator-verifyuser] {
                        max-width: 300px;
                        h3.amplify-heading {
                            position: relative;
                            visibility: hidden;
                            height: 3rem;
                            &:after {
                                content: "Account verification";
                                color: #3b4858;
                                font-size: 1.5rem;
                                font-weight: 700;
                                text-align: center;
                                pointer-events: none;
                                user-select: none;
                                visibility: visible;
                                position: absolute;
                                top: 0;
                                left: 15%;
                            }
                        }
                        .amplify-radiogroup {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            span.amplify-radio__label {
                                color: #3b4858;
                            }
                        }
                        .amplify-button {
                            &[type="submit"] {
                                &:after {
                                    content: "Submit";
                                    width: auto;
                                }
                            }
                            &.amplify-button--link {
                                display: block;
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1rem;
                                letter-spacing: -0.02em;
                                color: #3b4858;
                                border: none;
                                margin: 0 auto;
                                &:hover {
                                    background: none;
                                }
                                &:focus {
                                    outline: none;
                                    background: none;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    &[data-amplify-authenticator-confirmverifyuser] {
                        max-width: 300px;
                        h3.amplify-heading {
                            position: relative;
                            visibility: hidden;
                            height: 3rem;
                            &:after {
                                content: "Account verification";
                                color: #3b4858;
                                font-size: 1.5rem;
                                font-weight: 700;
                                text-align: center;
                                pointer-events: none;
                                user-select: none;
                                visibility: visible;
                                position: absolute;
                                top: 0;
                                left: 15%;
                            }
                        }
                        .amplify-radiogroup {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            span.amplify-radio__label {
                                color: #3b4858;
                            }
                        }
                        .amplify-button {
                            &[type="submit"] {
                                &:after {
                                    content: "Submit";
                                    width: auto;
                                }
                            }
                            &.amplify-button--link {
                                display: block;
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1rem;
                                letter-spacing: -0.02em;
                                color: #3b4858;
                                border: none;
                                margin: 0 auto;
                                &:hover {
                                    background: none;
                                }
                                &:focus {
                                    outline: none;
                                    background: none;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    &[data-amplify-authenticator-confirmsignup] {
                        max-width: 450px;
                        h4.amplify-heading {
                            position: relative;
                            visibility: hidden;
                            height: 3rem;
                            text-align: center;
                            &:after {
                                content: "Email verification";
                                color: #3b4858;
                                text-align: center;
                                pointer-events: none;
                                user-select: none;
                                visibility: visible;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                            }
                        }
                        p.amplify-text {
                            color: #3b4858;
                            text-align: center;
                            pointer-events: none;
                            user-select: none;
                        }
                        .amplify-textfield {
                            width: 250px;
                            margin: 0 auto;
                        }
                        .amplify-button {
                            &[type="submit"] {
                                &:after {
                                    content: "Submit";
                                    width: auto;
                                }
                            }
                            &[type="button"] {
                                display: block;
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1rem;
                                letter-spacing: -0.02em;
                                color: #3b4858;
                                border: none;
                                margin: 0 auto;
                                &:hover {
                                    background: none;
                                }
                                &:focus {
                                    outline: none;
                                    background: none;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    .amplify-alert--error {
                        background: transparent;
                        border: 1px solid rgba(252, 63, 54, 0.3);
                        border-radius: 4px;
                        color: #f12d23;
                        padding: 0.5rem 1rem;
                        border: none;
                        .amplify-alert__body {
                            font-size: 14px;
                        }
                        button {
                            margin-top: 0 !important;
                            padding: 0;
                            &:hover {
                                background: none;
                            }
                            &:active {
                                background: none;
                            }
                            &:focus {
                                outline: none;
                                background: none;
                                box-shadow: none;
                            }
                        }
                    }
                }
                .password-validations {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    max-width: 450px;
                    margin: 1rem auto;
                    font-size: 0.875rem;
                    line-height: 1.4375rem;
                    row-gap: 4px;
                    padding-left: 1rem;
                    letter-spacing: -0.02em;

                    & > span {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        &.error {
                            color: $primaryRed;
                        }

                        &.checked {
                            color: $primaryDark;
                        }
                    }
                }
                .reset-password {
                    display: block;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    letter-spacing: -0.02em;
                    color: $primaryDark;
                    border: none;
                    margin: 1.5rem auto;
                    &:hover {
                        background: none;
                    }
                    &:focus {
                        outline: none;
                        background: none;
                        border: none;
                        box-shadow: none;
                    }
                }
                .login-button {
                    display: block;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    letter-spacing: -0.02em;
                    color: $primaryDark;
                    border: none;
                    margin: 0 auto;
                    text-align: center;
                    text-decoration: none;
                    &:hover {
                        background: none;
                    }
                    &:focus {
                        outline: none;
                        background: none;
                        border: none;
                        box-shadow: none;
                    }
                    span {
                        text-decoration: underline;
                    }
                }
                .signup-button, .help-button {
                    display: block;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1rem;
                    letter-spacing: -0.02em;
                    color: #3b4858;
                    border: none;
                    margin: 0 auto;
                    margin-top: 1.5rem;
                    text-align: center;
                    text-decoration: none;
                    &:hover {
                        background: none;
                    }
                    &:focus {
                        outline: none;
                        background: none;
                        border: none;
                        box-shadow: none;
                    }
                }
                .help-button {
                    padding: unset;
                    font-size: 0.875rem;
                    display: flex;
                    margin-bottom: 2rem;

                    svg {
                        height: 1rem;
                        width: 1rem;
                        margin-left: 7px;
                        margin-right: 4px;
                    }
                    span {
                        font-weight: 600;
                    }
                }
                .btn-contact{
                    cursor: pointer;
                    color: #1d4650;
                }
                .msg-container{
                    p {
                        text-align: center;
                        font-size: 0.875rem;
                    }
                }
                .btn-contact{
                    text-decoration: underline;
                }
                .amplify-button--link{
                    font-size: 0.8125rem !important;
                }
            }
        }
    }

    &.rfq-view {
        height: 100%;
        width: 100%;
        padding: 0;
        [data-amplify-authenticator] {
            [data-amplify-container] {
                [data-amplify-router] {
                    [data-amplify-router-content] {
                        form {
                            max-width: 450px;
                            width: 100%;
                            .amplify-textfield {
                                width: 450px;
                                margin: 0 auto;
                            }
                            .amplify-flex fieldset {
                                flex-direction: row !important;
                                .amplify-heading {
                                    display: none !important;
                                }
                            }
                            &[data-amplify-authenticator-resetpassword] {
                                h3.amplify-heading {
                                    display: none;
                                }
                            }
                            &[data-amplify-authenticator-signin] {
                                .amplify-button {
                                    &[type="submit"] {
                                        &:after {
                                            max-width: 150px;
                                            content: "Log in";
                                        }
                                    }
                                }
                            }
                        }
                    }
                    form {
                        margin-top: 20px;
                        .amplify-field-group__field-wrapper {
                            text-align: start;
                        }
                        &[data-amplify-authenticator-verifyuser] {
                            h3.amplify-heading {
                                display: none;
                            }
                        }
                        &[data-amplify-authenticator-resetpassword] {
                            .amplify-field-group__field-wrapper {
                                text-align: center;
                            }
                            h3.amplify-heading {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        @include breakpoint-md-max() {
            [data-amplify-authenticator] {
                [data-amplify-container] {
                    [data-amplify-router] {
                        [data-amplify-router-content] {
                            form {
                                max-width: 350px;
                                width: 100%;
                                .amplify-textfield {
                                    width: 350px;
                                    margin: 0 auto;
                                }
                                .amplify-flex fieldset {
                                    flex-direction: column !important;
                                    .amplify-heading {
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint-md() {
    .auth-container {
        padding: 2.125rem;
        .auth-header {
            margin-top: 2.06rem;
            margin-bottom: 2.375rem;
        }
        .auth-background {
            width: auto;
            height: auto;
        }
        [data-amplify-authenticator] {
            [data-amplify-container] {
                [data-amplify-router] {
                    [data-amplify-router-content] {
                        .auth-message {
                            margin-bottom: 1.5rem;
                            .title {
                                font-size: 1.5rem;
                                line-height: 1.8rem;
                                margin-bottom: 0.5rem;

                                &:nth-child(2) { 
                                    margin-bottom: 1.5625rem;
                                }
                            }
                        }
                        form {
                            margin: 0 auto;
                            margin-bottom: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

// .rfq-view {
//     form {
//         max-width: 350px !important;
//     }
//     .amplify-flex fieldset {
//         flex-direction: row !important;
//     }
// }
