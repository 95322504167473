/* Regular */
@font-face {
    font-family: "Lato-Regular";
    src: url("./Lato-Regular.ttf");
}

@font-face {
    font-family: "Lato-Italic";
    src: url("./Lato-Italic.ttf");
}

/* Thin */
@font-face {
    font-family: "Lato-Thin";
    src: url("./Lato-Thin.ttf");
}

@font-face {
    font-family: "Lato-ThinItalic";
    src: url("./Lato-ThinItalic.ttf");
}

/* Light */
@font-face {
    font-family: "Lato-LightItalic";
    src: url("./Lato-LightItalic.ttf");
}

@font-face {
    font-family: "Lato-Light";
    src: url("./Lato-Light.ttf");
}

/* Semi-Bold*/
@font-face {
    font-family: "Lato-SemiBold";
    src: url("./Lato-Regular.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Lato-SemiBoldItalic";
    src: url("./Lato-Italic.ttf");
    font-weight: 700;
}

/* Bold */
@font-face {
    font-family: "Lato-Bold";
    src: url("./Lato-Bold.ttf");
}

@font-face {
    font-family: "Lato-BoldItalic";
    src: url("./Lato-BoldItalic.ttf");
}


/* Black */
@font-face {
    font-family: "Lato-Black";
    src: url("./Lato-Black.ttf");
}

@font-face {
    font-family: "Lato-BlackItalic";
    src: url("./Lato-BlackItalic.ttf");
}