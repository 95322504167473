@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.ShipmentsListBlockCardLoader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.5rem;
    width: 100%;
    padding: 1.5rem;
    border: 1px solid $grey100;
    border-radius: 0.5rem;
    overflow: hidden;
    .LeftSideContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 25%;
        gap: 0.5rem;
        .TopLine {
            width: 75%;
            height: 1rem;
        }
        .BottomLine {
            width: 100%;
            height: 0.75rem;
        }
    }
    .RightSideContainer {
        display: flex;
        flex-direction: column;
        width: 25%;
        gap: 0.5rem;
        .TopLine {
            width: 50%;
            height: 0.75rem;
        }
        .BottomLine {
            width: 100%;
            height: 0.75rem;
        }
    }
}
