@import "../../assets/styles/v2-styles/UiScheme.module.scss";

$selectBtnHeight: 2rem;
$optionsMenuWidth: 153px;

.UnicargoSelectBtn {
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: $selectBtnHeight;
    .SelectContainer {
        height: 100%;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        color: $primaryDark;
        border-radius: 1.5rem;
        border: 1px solid $grey200;
        padding: 5.5px 8px;
        padding-right: 0;
        cursor: pointer;
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none; /* Firefox all */
        user-select: none;
        font-size: 14px;
        .Label {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 0.25rem;
        }
        .ArrowIconContainer {
            padding: 0;
            .ArrowIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                path {
                    transition: fill 0.2s ease-in-out;
                    transition: scale 0.2s ease-in-out;
                    transform-origin: center;
                    fill: $primaryDark;
                    scale: 1;
                }
                &.Opened {
                    path {
                        scale: 1.2;
                    }
                }
            }
        }
        .ValueTag {
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: white;
            background-color: $primaryDark;
            border-radius: 100px;
            padding: 0;
            width: auto;
            height: 21px;
            opacity: 0;
            transform-origin: left;
            overflow: hidden;
            transition: opacity 0.2s ease-in-out;
            &.Selected {
                margin-right: 0.5rem;
                padding: 0.25rem 0.5rem;
                opacity: 1;
            }
            &.MultipleSelected {
                padding: 0;
                width: 1.5rem;
                height: 1.5rem;
                opacity: 1;
            }
        }
    }
    .OptionsMenu {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: $optionsMenuWidth;
        top: calc($selectBtnHeight + 0.5rem);
        padding: 0.5rem 1rem;
        background-color: white;
        border-radius: 1rem;
        border: 1px solid $grey200;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        justify-content: center;
        opacity: 0;
        transform-origin: top;
        overflow: hidden;
        z-index: $zIndexHigherOrderElements;
        &:not(.Opened) {
            display: none;
        }
        &.Opened {
            opacity: 1;
        }
        .LineSeparator {
            margin: 0.5rem 0;
        }
        .OptionContainer {
            width: 100%;
            height: 2rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;
            padding: 2px;
            font-size: 12px;
            &.SingleChoiceSelect {
                cursor: pointer;
                padding: 0.25rem;
                &:hover {
                    border-radius: 0.25rem;
                    background-color: $greyBackground;
                }
            }
        }
    }
}
