@import "../../../../assets/styles/v2-styles/UiScheme.module.scss";
@import "../../../../assets/styles/mixins";

.SwitchUsersContainer {
    position: relative;
    margin-top: 0.75rem;

    &:hover {
        .Connector,
        .Connector2 {
            display: block;
        }

        .CustomerList {
            display: grid;
        }
    }
}

.BtnSwitchAccount {
    background: transparent;
    border: none;
    color: $primaryDark;
    font-family: var(--font-regular);
    font-size: 14px;
    padding: 0.5rem;
    width: 100%;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background-color: $greyBackground;
        cursor: pointer;
    }
}

.Connector {
    height: 100%;
    width: 1rem;

    position: absolute;
    right: -0.5rem;
    bottom: 0;
    z-index: 0;

    display: none;
}

.Connector2 {
    height: 300%;
    width: 100%;

    position: absolute;
    right: -0.5rem;
    bottom: 100%;
    z-index: 0;

    display: none;
}

.CustomerList {
    position: absolute;
    bottom: 4px;
    right: -8px;
    transform: translate(100%, 0);

    padding-block: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1.5rem;
    border-radius: 4px;
    background: $white;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.14);
    list-style: none;
    max-height: 29rem;
    overflow-y: auto;
    width: max-content;

    gap: 0.5rem;

    display: none;
}

.CustomerPreview {
    color: $primaryDark;
    padding: 10px 0.5rem;
    font-size: 14px;
    border-radius: 4px;

    &:hover {
        background-color: $greyBackground;
        cursor: pointer;
    }
}
