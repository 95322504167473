@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.SidebarMobile {
    padding: 1.5rem 1rem 0;
    border-bottom: 1px solid var(--gray-light);
    width: 100%;
    background-color: var(--gray-white);
    z-index: $zIndexHigherOrderElements;
    .headContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;

        .iconsContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
        }

        .quoteButtonContainer {
            gap: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
//
/* 
Sidebar's menu drawer component.
:root because the drawer uses react's 'createPortal' fucntion to 'portal' to the root level.
*/
$gap-space: var(--default-gap);
:root .sidebarDrawerContainer {
    .sidebarDrawerPaperContainer {
        width: 280px;

        .sidebarDrawerInnerContainer {
            padding: 2.5rem 0 $gap-space;
            height: 100%;

            .returnIconContainer {
                border-bottom: 1px solid var(--gray-light);

                .closeDrawerIcon {
                    padding: 1rem 0.5rem;
                    height: 2rem;
                    width: 2rem;
                }
            }

            .sidebarDrawerBodyContainer {
                padding: 0 $gap-space;
                height: calc(
                    100% - ($gap-space * 2)
                ); // $defaultContainer-padding * 2 to counter the total containers padding
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                .navigationBarContainer {
                    width: 100%;
                }

                .utilsContainer {
                    width: 100%;
                    padding-bottom: $gap-space;

                    .emailUsIconContainer {
                        background-color: transparent;
                        width: 100%;
                        border: none;
                        gap: 0.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 0.75rem 0;
                    }

                    .logOutContainer {
                    }

                    .iconContainer {
                        display: flex;
                        gap: 0.5rem;
                        padding: 0.75rem 0;
                    }

                    *:not(:last-child) {
                        border-bottom: 1px solid var(--gray-light);
                    }
                }
            }
        }
    }
}
