.OutcomeModalView {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    height: 400px;
    .OutcomeTitle {
    }
    .OutcomeSubtitle {
    }
    .OutcomeIcon {
        border-radius: 50%;
    }
}
