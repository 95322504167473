@import "../../../assets/styles/v2-styles/UiScheme.module.scss";

.LoadingYaadSarigQueryParamsPaymentPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100svh;
    width: 100%;
    .PageTitle {
        font-size: 2rem;
    }
    .PageContent {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .PageContentTitle {
            font-size: 1.5rem;
        }
        .PageContentValue {
            font-size: 1rem;
            word-break: break-all;
            color: #b222ff;
        }
    }
}
