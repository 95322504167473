@import "../../../../../../../assets/styles/v2-styles/UiScheme.module.scss";

.SkeletonRouteTabContainer {
    display: flex;
    padding-top: 1.5rem;
    overflow: hidden;
    border: 1px solid $grey100;
    margin: 32px 31px;
    border-radius: 4px;
    gap: 80px;
    padding-left: 103px;
    padding-bottom: 1.5rem;
}

.verticalLine {
    height: 130%;
    width: 25px !important;
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.header {
    height: 15px;
    width: 100px;
}

.info {
    margin-top: 10px;
    height: 15px;
    width: 250px;
}
